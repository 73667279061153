import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../Styles/DashboardStyles.css';

function ThankYou() {
    let history = useHistory();

    function returnHome() {
        history.push("/");
    }

    function goToContactUs() {
        history.push("/ContactUs");
    }

    return (
        <div className="NavbarPadding" id="thanks">
            <h1 className="Main-title">Thank You!</h1>
            <p className="ThankYouText">
                Your subscription has been submitted. An Enns Brothers representative will be in touch with you soon!
                For further information you can contact us using our contact form.
            </p>
            <Button onClick={goToContactUs} className="SubscribeButtonsA" id="contactButton">Contact Us</Button>
            <Button onClick={returnHome} className="SubscribeButtonsA">Back to Dashboard</Button>
        </div>
    );
}

export default ThankYou;