import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import '../Styles/DashboardStyles.css';
import '../Styles/Admin.css';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { listUserSubscriptions } from '../graphql/queries';
import { updateUserSubscriptions, deleteUserSubscriptions } from '../graphql/mutations';
import AssignDevicesStatuses from '../Components/Subscriptions/AssignDevicesStatuses';

function ViewUserInfo() {
    const [subscriptions, setSubscriptions] = useState([]);

    let history = useHistory();
    const location = useLocation();

    const userData = location.state;
    let verifiedAccess = false;

    // Checks if any user data was passed in. If no user data was passed in, redirect user back to home page. If user data was passed in, set verifiedAccess to true (allows page to render).
    // Prevents user from direcctly accessing the page via link.
    if(typeof userData === 'undefined'){
        history.push("/");
    }
    else{
        verifiedAccess = true;
    }

    useEffect(() => {
        fetchSubscriptions();
    }, []);
 
    /**
     * Fetches all subscriptions that the user currently has and stores them in 'subscriptions' state variable.
     */
    const fetchSubscriptions = async () => {
        try{
            // userId will have to be changed to the ID of the user that the admin is currently viewing.
            let userId = userData.userSub;

            const subscriptionData = await API.graphql(graphqlOperation(listUserSubscriptions, {USER_ID: userId}));
            const subscriptionDataList = subscriptionData.data.listUserSubscriptions.items;

            setSubscriptions(AssignDevicesStatuses(subscriptionDataList));
        } catch(error){
            
        }
    }
    
    /**
     * Updates a record's STATUS attribute in the userSubscriptions database table to 'Active'.
     * 
     * @param {Object} subscription - Object that contains the device ID and user ID of the record that needs to be updated to 'Active'.
     * @param {string} subscription.DEVICE_IDENTIFICATION_NUMBER - The ID of the subscribed device that is being updated to 'Active' status.
     * @param {string} subscription.USER_ID - The ID of the user whose subscription is being updated to 'Active' status.
     */
    const handleAccept = async (subscription) => {
        let startDate = new Date();
        let endDate = new Date().setDate(startDate.getDate() + 365);

        await API.graphql(graphqlOperation(updateUserSubscriptions, 
            {input: {
                DEVICE_IDENTIFICATION_NUMBER: subscription.DEVICE_IDENTIFICATION_NUMBER, 
                USER_ID: subscription.USER_ID, 
                START_DATE: startDate.toISOString().substring(0, 10), 
                END_DATE: new Date(endDate).toISOString().substring(0,10)
            }
        }));
        fetchSubscriptions();
    }


    /**
     * Deletes a record in the userSubscriptions database table.
     * 
     * @param {Object} subscription - Object that contains the device ID and user ID of the record that needs to be deleted.
     * @param {string} subscription.DEVICE_IDENTIFICATION_NUMBER - The ID of the subscribed device that is being deleted.
     * @param {string} subscription.USER_ID - The ID of the user whose subscription is being updated deleted.
     */
    const handleDecline = async (subscription) => {
        await API.graphql(graphqlOperation(deleteUserSubscriptions, {input: {DEVICE_IDENTIFICATION_NUMBER: subscription.DEVICE_IDENTIFICATION_NUMBER, USER_ID: subscription.USER_ID}}));
        fetchSubscriptions();
    }


    /**
     * Updates a record's STATUS attribute in the userSubscriptions database table to 'Active' and gives the record a new END_DATE.
     * 
     * @param {Object} subscription - Object that contains the device ID and user ID of the record that needs to be updated.
     * @param {string} subscription.DEVICE_IDENTIFICATION_NUMBER - The ID of the subscribed device that is being updated.
     * @param {string} subscription.USER_ID - The ID of the user whose subscription is being updated.
     * @param {string} subscription.END_DATE - The subscription end date that will be used to calculate a new end date.
     */
    const handleExtend = async (subscription) => {
        let newEndDate = new Date(subscription.END_DATE);
        newEndDate.setDate(newEndDate.getDate() + 365);
        newEndDate = newEndDate.toISOString().substring(0,10);

        await API.graphql(graphqlOperation(updateUserSubscriptions, {input: {DEVICE_IDENTIFICATION_NUMBER: subscription.DEVICE_IDENTIFICATION_NUMBER, USER_ID: subscription.USER_ID, END_DATE: newEndDate}}));
        fetchSubscriptions();
    }


    /**
     * Updates a record's STATUS attribute in the userSubscriptions database table to 'Active' and gives the record a new END_DATE.
     * 
     * @param {Object} subscription - Object that contains the device ID and user ID of the record that needs to be updated.
     * @param {string} subscription.DEVICE_IDENTIFICATION_NUMBER - The ID of the subscribed device that is being updated.
     * @param {string} subscription.USER_ID - The ID of the user whose subscription is being updated.
     */
    const handleRenew = async (subscription) => {
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 365);
        endDate = endDate.toISOString().substring(0,10);

        await API.graphql(graphqlOperation(updateUserSubscriptions, {input: {DEVICE_IDENTIFICATION_NUMBER: subscription.DEVICE_IDENTIFICATION_NUMBER, USER_ID: subscription.USER_ID, END_DATE: endDate}}));
        fetchSubscriptions();
    }


    /**
     * Returns JSX code that displays information about the user's device subscription.
     * 
     * @param {Object} subscription - The subscription info about a device to display.
     * @param {string} subscription.DEVICE_IDENTIFICATION_NUMBER - The ID of the device that the user is subscribed to.
     * @param {string} subscription.END_DATE - The end date of the subscription.
     * @param {string} subscription.STATUS - The status of the subscription.
     * 
     * @returns JSX code that displays the subscribed device information and its current status.
     */
     function SubscribedDevice(subscription) {
        let statusIndicator;
        let subRight;
    
        if(subscription.STATUS === "Active"){
            statusIndicator = "circleF";
            subRight = <div className="SubRight"><p>{`Expires: ${subscription.END_DATE}`}</p></div>;
        }
        else if(subscription.STATUS === "Pending"){
            statusIndicator = "circleE";
            subRight = <div className="SubRight"><button id='approve' onClick={() => handleAccept(subscription)}>Approve</button><button id='decline' onClick={() => handleDecline(subscription)}>Decline</button></div>;
        }
        else if(subscription.STATUS === "Expiring"){
            let endDate = new Date(subscription.END_DATE);
            let today = new Date();
            
            // Finds the difference in days between today and subscription end date
            let difference = endDate.getTime() - today.getTime();
            let days = Math.ceil(difference / (1000 * 3600 * 24));
    
            statusIndicator = "circleE";
            subRight = <div className="SubRight"><p>{`Expires in ${days} days`}</p><button id='extend' onClick={() => handleExtend(subscription)}>Extend</button></div>;
        }
        else{
            statusIndicator = "circleG";
            subRight = <div className="SubRight"><p>{`Expired: ${subscription.END_DATE}`}</p><button id='renew' onClick={() => handleRenew(subscription)}>Renew</button><button id='remove' onClick={() => handleDecline(subscription)}>Remove</button></div>;
        }

        return(
            <div className="UserSubA" key={subscription.DEVICE_IDENTIFICATION_NUMBER}>
                <div className="SubLeft">
                    <span className={statusIndicator}></span>
                    <p><b>Station ID:</b> {subscription.DEVICE_IDENTIFICATION_NUMBER}</p>
                </div>
                {subRight}
            </div>
        )
    }

    function returnHome() {
        history.push("/Admin");
    }

    return (
        <div>
        {verifiedAccess && (
        <div class="NavbarPadding">
            <h1 className="Main-title">View User</h1>
            
            <div className="GeneralInfoBox">
                <h2>User Information</h2>
                <div className="UserInfoBox">
                    <div className="UserInfoBoxA">
                        <h4>First Name</h4>
                        <p>{userData.firstName}</p>

                        <h4>Last Name</h4>
                        <p>{userData.lastName}</p>

                        <h4>Farm Name</h4>
                        <p>{userData.farmName}</p>
                    </div>
                    <div className="UserInfoBoxA">
                        <h4>Email</h4>
                        <p>{userData.email}</p>

                        <h4>Phone Number</h4>
                        <p>{userData.phoneNumber}</p>

                        <h4>Farm Location</h4>
                        <p>{userData.farmAddress}, {userData.farmCity}</p>
                    </div>
                </div>
            </div>
            <div className="GeneralInfoBox">
                <h2>User Subscription Status</h2>
                {subscriptions.map((subscription) => (
                    SubscribedDevice(subscription)
                ))}
            </div>
            <div className="GeneralInfoBox">
                <h2>User Fields</h2>
                <div className="FieldBox">
                    <div className="SubLeft">
                        <label>Field Shapefile: </label>
                    </div>
                    <div className="SubRight">
                        <button className="FieldShapeButton" disabled>Choose File</button>
                    </div>
                </div>
            </div>
            <Button onClick={returnHome} className="SubscribeButtonsA">Back to Admin Page</Button>
        </div>
        )}
        </div>
    );
}

export default ViewUserInfo;