import React from 'react';
import '../Styles/ChangeInfo.css';
import { Container, Button, Form } from 'react-bootstrap';

// Check the confirm email and update the user email with the value in formState
async function changeEmail() {
  if (formState.Email === formState.Confirm) {
    alert('Sucessfully Changed Email');
    window.location.href = './Profile';
  } else {
    alert('Email Does Not Match');
  }
}

const formState = { Email: '', Confirm: ''};

// Paste the new values to the formState
function updateFormState(key, value) {
  formState[key] = value;
}

// Return the form for the user to enter the new email
function ChangeEmail() {

  return (
    <Container id="main">
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" ></link>
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap" rel="stylesheet"></link>
        <div id="Profile">
          <br/>
          <Form className="changeEmail">
            <h2>Change Email</h2>
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">New Email: </Form.Label>
              <Form.Control type="email" placeholder="New Email" onChange={e => updateFormState('Email', e.target.value)} />
            </Form.Group>
            <br/>
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Confirm Email: </Form.Label>
              <Form.Control type="email" placeholder="Confirm Email" onChange={e => updateFormState('Confirm', e.target.value)} />
            </Form.Group>
            <br/>
            <Button onClick={changeEmail}><span className="registerButton">Confirm</span></Button>
            <br/>
          </Form>
        </div>
    </Container>
  )
}

export default ChangeEmail
