import React, { useState } from 'react';
import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';
import awsExports from "../aws-exports";
import '../Styles/DashboardStyles.css';

Amplify.configure(awsExports);

function UserInfo() {
    // Delare UseState variables

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [farmName, setFarmName] = useState('');
    const [farmAddress, setFarmAddress] = useState('');

    // Assign the value from user's attributes to UseState variables
    Auth.currentAuthenticatedUser().then((userInfo) => {
        setEmail(userInfo["attributes"].email)
        setFirstName(userInfo["attributes"].given_name)
        setLastName(userInfo["attributes"].family_name)
        setPhoneNumber(userInfo["attributes"].phone_number)
        setFarmName(userInfo["attributes"]["custom:farm_name"])
        setFarmAddress(userInfo["attributes"]["custom:farm_address"])
    })

    return (
        <div className="UserInfoBox">
            <div className="UserInfoBoxA">
                <h4>First Name</h4>
                <p>{firstName}</p>

                <h4>Last Name</h4>
                <p>{lastName}</p>

                <h4>Farm Name</h4>
                <p>
                    {farmName}
                    {/* TODO: Add Farm Name */}
                </p>
            </div>
            <div className="UserInfoBoxA">
                <h4>Email</h4>
                <p>{email}</p>

                <h4>Phone Number</h4>
                <p>{phoneNumber}</p>

                <h4>Farm Location</h4>
                <p>
                    {farmAddress}
                    {/* TODO: Add Farm Name */}
                </p>
            </div>
        </div>
    );
}

export default UserInfo;