import React from "react";
import NotesList from "../Components/Notes/NotesList";
import { useEffect, useState } from 'react';
import {nanoid} from 'nanoid';
import '../Styles/Home.css';
import '../Styles/DashboardStyles.css';
import '../Styles/Tables.css';
import PublicWeather from '../Components/PublicWeather';
import WeatherStation from "../Components/WeatherStation";
import SelectWeatherStation from "../Components/Map/SelectWeatherStation.js";
import {API, graphqlOperation, Auth} from 'aws-amplify';
import { listUserNotes, listUserSubscriptions } from '../graphql/queries';
import { createUserNotes } from "../graphql/mutations";

export function Home()
{
  //The initial state of the date
  const [date] = useState(new Date());
  const [fields, setFields] = useState([]);
  const [farmName, setFarmName] = useState('');
  const [currentChosenDevice, setCurrentChosenDevice] = useState('');

  var dateOptions = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'};
  var dateOptions2 = {year: 'numeric', month: 'numeric', day: 'numeric'};

    //Checks if user coordinates are valid. If not valid changes them to Winnipeg coordinates.
    const checkCoordinates = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const userLat = Number((user['attributes']["custom:lat"]));
        const userLon = Number((user['attributes']["custom:lon"]));

        if(Math.abs(userLat) > 90 || Math.abs(userLon) > 180 || userLat === '' || userLon === ''){
            await Auth.updateUserAttributes(user, {
                'custom:lat': "49.8951",
                'custom:lon': "-97.1384"
            })
            window.location.reload();
        }
    }


    // Assign the value from user's attributes to UseState variables
    Auth.currentAuthenticatedUser().then((userInfo) => {
        setFarmName(userInfo["attributes"]["custom:farm_name"])
    })

      //The initial state of the notes and the function to update it
      const [notes, setNotes] = useState([]);

    useEffect(() => {
        fetchNotes();
        checkCoordinates();
    }, [])

    const fetchNotes = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser ();
            let UserSubIn = user['username'];
            const savedNotes = await API.graphql(graphqlOperation(listUserNotes, {UserSub: UserSubIn, NoteDate: date.toLocaleDateString("en-US", dateOptions2), limit: 1000}));
            if(savedNotes){
                setNotes(savedNotes.data.listUserNotes.items.reverse());
            }
        } catch(error){
            console.log('error on displaying nots: ', error);
        }
    }

    //Everytime the page is loaded, it will run the fetchFieldData function
    useEffect(() => {
        fetchFieldData();
    }, []);

    //Fetches the data from the graphqlAPI and stores the items into a variable
    const fetchFieldData = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser ();
            let UserSubIn = user['username'];
            const fieldData = await API.graphql(graphqlOperation(listUserSubscriptions, {USER_ID: UserSubIn}));
            const fieldDataList = fieldData.data.listUserSubscriptions.items;
            let active = []
            // Assigns a status to each subscription based on the end date.
            for(var i = 0; i < fieldDataList.length; i++){

                if(fieldDataList[i].END_DATE === "Pending"){
                    fieldDataList[i].STATUS = "Pending";
                }
                else{
                    let endDate = new Date(fieldDataList[i].END_DATE);
                    let today = new Date();
            
                    // Finds the difference in days between today and subscription end date
                    let difference = endDate.getTime() - today.getTime();
                    let days = Math.ceil(difference / (1000 * 3600 * 24));

                    if(days > 30){
                        fieldDataList[i].STATUS = "Active";
                        active.push(fieldDataList[i]);
                    }
                    if(days < 30 && days > 0){
                        fieldDataList[i].STATUS = "Expiring";
                        active.push(fieldDataList[i]);
                    }
                    if(days <= 0){
                        fieldDataList[i].STATUS = "Expired";
                    }
                }
            }
            setFields(active);
        } catch(error){
            console.log('error on fetching field data', error);
        }
    }
    
    async function addNote(text){
        let user = await Auth.currentAuthenticatedUser ();
        let UserSubIn = user['username'];
        const newNote = {
            UserSub: UserSubIn,
            NoteID: nanoid(),
            NoteText: text,
            NoteDate: (new Date()).toLocaleDateString("en-US", dateOptions)
        }
        await API.graphql(graphqlOperation(createUserNotes, {input: newNote}));
        fetchNotes();
    }

    //function to delete a note using the id
    function deleteNote(id){
        const newNotes = notes.filter((note)=> note.id !== id);
        setNotes(newNotes);
    }

return(
    <div className="Main-Dashboard NavbarPadding">
        <h1 className="Main-title">EB Weather - {farmName}</h1>
        <br/>
        <div id="DashboardContent">
            <div id="DashboardLeft">
                <PublicWeather />
                <SelectWeatherStation currentChosenDevice={currentChosenDevice}/>
                <div id="DashboardLeft">
                    <div className="filter">
                        {fields.map((field) => (<WeatherStation deviceInfo={field} setCurrentChosenDevice={setCurrentChosenDevice}/>))}
                    </div>
                </div>
            </div>
            <div id="DashboardRight">
                <div className="listnotes">
                <NotesList notes={notes} 
                handleAddNote={addNote} 
                handleDeleteNote={deleteNote}
                handleFetchNote={fetchNotes}/>
                </div>
            </div>
        </div>
    </div>
  )
}


export default Home;