/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWeatherDataToday = /* GraphQL */ `
  mutation CreateWeatherDataToday($input: CreateWeatherDataTodayInput!) {
    createWeatherDataToday(input: $input) {
      DATE_RECORDED
      DEVICE_IDENTIFICATION_NUMBER
      SOIL_MOISTURE_ONE
      SOIL_MOISTURE_TWO
      SOIL_MOISTURE_THREE
      SOIL_MOISTURE_FOUR
      SOIL_TEMPERATURE_ONE
      SOIL_TEMPERATURE_TWO
      SOIL_TEMPERATURE_THREE
      SOIL_TEMPERATURE_FOUR
      TEMPERATURE_OUTSIDE
      WIND_SPEED
      RAINFALL
      HUMIDITY_OUTSIDE
      WIND_DIRECTION
    }
  }
`;
export const updateWeatherDataToday = /* GraphQL */ `
  mutation UpdateWeatherDataToday($input: UpdateWeatherDataTodayInput!) {
    updateWeatherDataToday(input: $input) {
      DATE_RECORDED
      DEVICE_IDENTIFICATION_NUMBER
      SOIL_MOISTURE_ONE
      SOIL_MOISTURE_TWO
      SOIL_MOISTURE_THREE
      SOIL_MOISTURE_FOUR
      SOIL_TEMPERATURE_ONE
      SOIL_TEMPERATURE_TWO
      SOIL_TEMPERATURE_THREE
      SOIL_TEMPERATURE_FOUR
      TEMPERATURE_OUTSIDE
      WIND_SPEED
      RAINFALL
      HUMIDITY_OUTSIDE
      WIND_DIRECTION
    }
  }
`;
export const deleteWeatherDataToday = /* GraphQL */ `
  mutation DeleteWeatherDataToday($input: DeleteWeatherDataTodayInput!) {
    deleteWeatherDataToday(input: $input) {
      DATE_RECORDED
      DEVICE_IDENTIFICATION_NUMBER
      SOIL_MOISTURE_ONE
      SOIL_MOISTURE_TWO
      SOIL_MOISTURE_THREE
      SOIL_MOISTURE_FOUR
      SOIL_TEMPERATURE_ONE
      SOIL_TEMPERATURE_TWO
      SOIL_TEMPERATURE_THREE
      SOIL_TEMPERATURE_FOUR
      TEMPERATURE_OUTSIDE
      WIND_SPEED
      RAINFALL
      HUMIDITY_OUTSIDE
      WIND_DIRECTION
    }
  }
`;
export const createDeviceLocation = /* GraphQL */ `
  mutation CreateDeviceLocation($input: CreateDeviceLocationInput!) {
    createDeviceLocation(input: $input) {
      DEVICE_IDENTIFICATION_NUMBER
      Latitude
      Longitude
    }
  }
`;
export const updateDeviceLocation = /* GraphQL */ `
  mutation UpdateDeviceLocation($input: UpdateDeviceLocationInput!) {
    updateDeviceLocation(input: $input) {
      DEVICE_IDENTIFICATION_NUMBER
      Latitude
      Longitude
    }
  }
`;
export const deleteDeviceLocation = /* GraphQL */ `
  mutation DeleteDeviceLocation($input: DeleteDeviceLocationInput!) {
    deleteDeviceLocation(input: $input) {
      DEVICE_IDENTIFICATION_NUMBER
      Latitude
      Longitude
    }
  }
`;
export const createUserNotes = /* GraphQL */ `
  mutation CreateUserNotes($input: CreateUserNotesInput!) {
    createUserNotes(input: $input) {
      NoteDate
      UserSub
      NoteID
      NoteText
    }
  }
`;
export const updateUserNotes = /* GraphQL */ `
  mutation UpdateUserNotes($input: UpdateUserNotesInput!) {
    updateUserNotes(input: $input) {
      NoteDate
      UserSub
      NoteID
      NoteText
    }
  }
`;
export const deleteUserNotes = /* GraphQL */ `
  mutation DeleteUserNotes($input: DeleteUserNotesInput!) {
    deleteUserNotes(input: $input) {
      NoteDate
      UserSub
      NoteID
      NoteText
    }
  }
`;
export const createUserSubscriptions = /* GraphQL */ `
  mutation CreateUserSubscriptions($input: [CreateUserSubscriptionsInput]) {
    createUserSubscriptions(input: $input) {
      DEVICE_IDENTIFICATION_NUMBER
      USER_ID
      DEVICE_NAME
      START_DATE
      END_DATE
    }
  }
`;
export const updateUserSubscriptions = /* GraphQL */ `
  mutation UpdateUserSubscriptions($input: UpdateUserSubscriptionsInput!) {
    updateUserSubscriptions(input: $input) {
      DEVICE_IDENTIFICATION_NUMBER
      USER_ID
      DEVICE_NAME
      START_DATE
      END_DATE
    }
  }
`;
export const deleteUserSubscriptions = /* GraphQL */ `
  mutation DeleteUserSubscriptions($input: DeleteUserSubscriptionsInput!) {
    deleteUserSubscriptions(input: $input) {
      DEVICE_IDENTIFICATION_NUMBER
      USER_ID
      DEVICE_NAME
      START_DATE
      END_DATE
    }
  }
`;
export const createCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns = /* GraphQL */ `
  mutation CreateCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(
    $input: CreateCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnnsInput!
  ) {
    createCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(input: $input) {
      id
      name
      email
    }
  }
`;
export const updateCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns = /* GraphQL */ `
  mutation UpdateCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(
    $input: UpdateCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnnsInput!
  ) {
    updateCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(input: $input) {
      id
      name
      email
    }
  }
`;
export const deleteCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns = /* GraphQL */ `
  mutation DeleteCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(
    $input: DeleteCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnnsInput!
  ) {
    deleteCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(input: $input) {
      id
      name
      email
    }
  }
`;
