import React, { useState, useEffect } from 'react'
import '../Styles/ChangeInfo.css';
import { Container, Button, Form } from 'react-bootstrap';
import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';
import awsExports from "../aws-exports";
import { useHistory } from "react-router-dom";

Amplify.configure(awsExports);

// Validation for phone number
function PhoneNumberValidation(PhoneNumber) {
  let str = PhoneNumber.toString();
  const subStr = str.substring(0, 1);
  const length = str.length
  const dashCount = 0;

  for (let i=0; i <= length; i++) {
    if (str[i] == "-") {
      dashCount += 1;
    }
  }

  if (dashCount != 0) {
    str = str.ReplaceAll('-', '');
  }

  if (length < 11) {
    str = "+1" + str;
  } else {
    if (length < 12) {
      str = "+" + str;
    }
  }

  return str;
}

// Return the form for the user to enter the new information
function ChangeInfo() {
  let history = useHistory();


   // Delare UseState variables
   const [Email, setEmail] = useState('');
   const [FirstName, setFirstName] = useState('');
   const [LastName, setLastName] = useState('');
   const [Address, setAddress] = useState('');
   const [City, setCity] = useState('');
   const [Province, setProvince] = useState('');
   const [Country, setCountry] = useState('');
   const [PhoneNumber, setPhoneNumber] = useState('');
   const [farmName, setFarmName] = useState('');
   const [farmLat, setFarmLat] = useState('');
   const [farmLon, setFarmLon] = useState('');
   //const [AltPhoneNumber, setAltPhoneNumber] = useState('');
 
   // Assign the value from user's attributes to UseState variables
   useEffect(() => {
    Auth.currentAuthenticatedUser().then((userInfo) => {
      setEmail(userInfo["attributes"].email)
      setFirstName(userInfo["attributes"].given_name)
      setLastName(userInfo["attributes"].family_name)
      setAddress(userInfo["attributes"].address)
      setCity(userInfo["attributes"]["custom:city"])
      setProvince(userInfo["attributes"]["custom:province"])
      setCountry(userInfo["attributes"]["custom:country"])
      setPhoneNumber(userInfo["attributes"].phone_number)
      setFarmName(userInfo["attributes"]["custom:farm_name"])
      setFarmLat(userInfo["attributes"]["custom:lat"])
      setFarmLon(userInfo["attributes"]["custom:lon"])
      //setAltPhoneNumber(userInfo["attributes"]["custom:altphonenumber"])
    })
   }, []) ; 

// Check the new information and update the user information with the values in formState
async function changeInfo() {
  let valid = true;

  for (let key of [FirstName, LastName, Address, City, Province, Country, PhoneNumber, Email, farmName, farmLat]) {
    if (key === "") {
      valid = false;
    }
  }

if (valid) {
  let user = await Auth.currentAuthenticatedUser();

  let farmLatitude = farmLat;
  let farmLongitude = farmLon;

  if(Math.abs(farmLat) > 90 || Math.abs(farmLon) > 180){
    farmLatitude = "49.8951";
    farmLongitude = "-97.1384";
  }

  let result = await Auth.updateUserAttributes(user, {
      'given_name': FirstName,
      'family_name': LastName,
      'address': Address,
      'custom:city': City,
      'custom:province': Province,
      'custom:country': Country,
      'phone_number': PhoneNumberValidation(PhoneNumber),
      'email': Email,
      'custom:farm_name': farmName,
      'custom:lat': farmLatitude,
      'custom:lon': farmLongitude
      /*'custom:altphonenumber': PhoneNumberValidation(formState.AltPhoneNumber)*/
    })
    alert('Sucessfully Changed Information');
    history.push("/Profile");
  } else {
    alert('All the fields must be filled');
    }
  }

  return (
    <Container id="main">
        <div id="Profile" class="NavbarPadding">
          {
            /*             
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Alternative Phone Number: </Form.Label>
              <Form.Control type="tel" placeholder="Alternative Phone Number" onChange={e => updateFormState('AltPhoneNumber', e.target.value)} />
            </Form.Group>
            <br/>
            <Button onClick={changeInfo}><span className="registerButton">Confirm</span></Button>
            <br/> */
            /*formState.FirstName.value*/
          }
          <h1 class="maintitle">Edit Profile</h1>
          <Form noValidate>

            <div className="changeInfo">
              <h2>Basic Information</h2>
              <Form.Group className="formGroup">
                <Form.Label className="fieldName">First Name: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="First Name" 
                  value={FirstName} onChange={e => setFirstName(e.target.value)} />
              </Form.Group>
              
              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Last Name: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="Last Name" 
                value={LastName} onChange={e => setLastName(e.target.value)} />
              </Form.Group>

              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Address: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="Address" 
                value={Address} onChange={e => setAddress(e.target.value)} />
              </Form.Group>

              <Form.Group className="formGroup">
                <Form.Label className="fieldName">City: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="City" 
                value={City} onChange={e => setCity(e.target.value)} />
              </Form.Group>

              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Province: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="Province" 
                value={Province} onChange={e => setProvince(e.target.value)} />
              </Form.Group>

              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Country: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="Country" 
                value={Country} onChange={e => setCountry(e.target.value)} />
              </Form.Group>
            </div>

            <div className="changeInfo">
              <h2>Contact Information</h2>
              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Phone Number: </Form.Label>
                <Form.Control className="fieldInput" type="tel" placeholder="Phone Number" 
                value={PhoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
              </Form.Group>

              {/* This is the Email */}
              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Email: </Form.Label>
                <Form.Control className="fieldInput" type="email" placeholder="New Email" 
                value={Email} onChange={e => setEmail(e.target.value)} />
              </Form.Group>
            </div>

            <div className="changeInfo">
              <h2>Farm Information</h2>
              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Farm Name: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="Farm Name" 
                value={farmName} onChange={e => setFarmName(e.target.value)} />
              </Form.Group>

              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Farm Latitude: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="00.0000" 
                value={farmLat} onChange={e => setFarmLat(e.target.value)} />
              </Form.Group>

              <Form.Group className="formGroup">
                <Form.Label className="fieldName">Farm Longitude: </Form.Label>
                <Form.Control className="fieldInput" type="text" placeholder="-00.0000" 
                value={farmLon} onChange={e => setFarmLon(e.target.value)} />
              </Form.Group>
            </div>

            <div className="changeInfo">
              <Button onClick={changeInfo}><span className="registerButton">Confirm</span></Button>
            </div>
          </Form>
        </div>
    </Container>
  )
}

export default ChangeInfo
