import React from "react";
import Contact from './Contact';
import '../Styles/DashboardStyles.css';
import '../Styles/Tables.css';
import '../Styles/Modal.css';

function ContactUs() {
    return (
      <div className="NavbarPadding" id="addField">
      <h1 className="Main-title">Contact Us</h1>
      <Contact />
      {/*
        <Modal className='modal'
        show={this.modalOn} onHide={this.modalOff}>
          <Modal.Header></Modal.Header>
          <Modal.Body><Contact /></Modal.Body>
          <Modal.Footer>
            <Button className='close' variant="secondary" onClick={test}>
              x
            </Button>
          </Modal.Footer>
        </Modal>
      */}
      </div>
    );
  }

export default ContactUs;