import React from 'react'
import { Auth}  from 'aws-amplify';

// Function used To Check If Local Storage Can Be Used
// https://developer.mozilla.org/
function storageAvailable(type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}
// If Local Storage is Available, Set To True
    //Local Storage Contains CurrentWeatherJSON, CurrentWeatherTime,
    //HistoricWeatherJSON, HistoricWeatherTime, GeoLocJSON
var localAvailable = false;
if (storageAvailable('localStorage')){
    localAvailable = true; 
}

/**
 * @description This Class Will Submit Three API Requests to OpenWeather.org. The First Gets The User's Location AS A LatLon Coordinate 
 *              And Stores It In Local Storage Permanently. The Second Call Will Get Yesterdays Data For The User's Geocoded Location. 
 *              The Third Will Get The Data For Right Now.
 * @export PublicWeather
 * @class PublicWeather
 * @extends {React.Component}
 */
export default class PublicWeather extends React.Component {
    state = {
        geoData: null,
        geoLoading: true,
        currentLoading: true,
        historicLoading: true
    };
    async componentDidMount() {
        // OpenWeather API Key
        const APIKey = '1b380f8edcdb3130f3f754ad74e7671e';
        
        /*
        *   Location Stuff
        */
        const user = await Auth.currentAuthenticatedUser ();
        this.setState({
            latWeather: user['attributes']['custom:lat'],
            lonWeather: user['attributes']['custom:lon'],
            userCity: user['attributes']['custom:city'],
            geoLoading: false
        });

        if (!this.state.geoLoading) {
            /*
            *   OpenWeather OneCall API For Historic Data
            */
            const currentDate = Date.now();
            const yesterDate = Math.floor((currentDate / 1000)) - 86400;
            
            const weatherHistoricURLStart = 'https://api.openweathermap.org/data/2.5/onecall/timemachine?lat=';
            var latWeather = this.state.latWeather;
            var lonWeather = this.state.lonWeather;
            const fullHistoricURL = weatherHistoricURLStart + latWeather + '&lon=' + lonWeather + '&dt=' + yesterDate + '&units=metric&appid=' + APIKey;
            if (!localAvailable) {
                const responseHistoric = await fetch( fullHistoricURL );
                const historicData = await responseHistoric.json();
                this.setState( {
                    HistoricData: historicData,
                    historicLoading: false
                } );
            }
            else if (localAvailable && !localStorage.getItem('HistoricWeatherJSON')) {
                const responseHistoric = await fetch( fullHistoricURL );
                const historicData = await responseHistoric.json();
                localStorage.setItem('HistoricWeatherJSON', JSON.stringify(historicData));
                localStorage.setItem('HistoricWeatherTime', currentDate);
                this.setState( {
                    HistoricData: historicData,
                    historicLoading: false
                } );
            }
            else if (localAvailable && localStorage.getItem('HistoricWeatherJSON') && localStorage.getItem('HistoricWeatherTime') <= (currentDate - 600000)) {
                const responseHistoric = await fetch( fullHistoricURL );
                const historicData = await responseHistoric.json();
                localStorage.setItem('HistoricWeatherJSON', JSON.stringify(historicData));
                localStorage.setItem('HistoricWeatherTime', currentDate);
                this.setState( {
                    HistoricData: historicData,
                    historicLoading: false
                } );
            }
            else if (localAvailable && localStorage.getItem('HistoricWeatherJSON') && localStorage.getItem('HistoricWeatherTime') > (currentDate - 600000)) {
                this.setState( {
                    HistoricData: JSON.parse(localStorage.getItem('HistoricWeatherJSON')),
                    historicLoading: false
                } );
            }

            /*
            *   OpenWeather Current Weather API
            */
            const weatherCurrentURLStart = 'https://api.openweathermap.org/data/2.5/weather?lat=';
            const weatherCurrentURLFull = weatherCurrentURLStart + latWeather + '&lon=' + lonWeather + '&units=metric&appid=' + APIKey;
            if (!localAvailable) {
                const responseCurrent = await fetch( weatherCurrentURLFull );
                const currentData = await responseCurrent.json();
                this.setState( {
                    CurrentData: currentData,
                    currentLoading: false
                } );
            }
            else if (localAvailable && !localStorage.getItem('CurrentWeatherJSON')) {
                const responseCurrent = await fetch( weatherCurrentURLFull );
                const currentData = await responseCurrent.json();
                localStorage.setItem('CurrentWeatherJSON', JSON.stringify(currentData));
                localStorage.setItem('CurrentWeatherTime', currentDate);
                this.setState( {
                    CurrentData: currentData,
                    currentLoading: false
                } );
            }
            else if (localAvailable && localStorage.getItem('CurrentWeatherJSON') && localStorage.getItem('CurrentWeatherTime') <= (currentDate - 600000)) {
                const responseCurrent = await fetch( weatherCurrentURLFull );
                const currentData = await responseCurrent.json();
                localStorage.setItem('CurrentWeatherJSON', JSON.stringify(currentData));
                localStorage.setItem('CurrentWeatherTime', currentDate);
                this.setState( {
                    CurrentData: currentData,
                    currentLoading: false
                } );
            }
            else if (localAvailable && localStorage.getItem('CurrentWeatherJSON') && localStorage.getItem('CurrentWeatherTime') > (currentDate - 600000)) {
                this.setState( {
                    CurrentData: JSON.parse(localStorage.getItem('CurrentWeatherJSON')),
                    currentLoading: false
                } );
            }
        }
    }
    render() { 
        if (this.state.geoLoading || this.state.currentLoading || this.state.historicLoading) {
            return <div>
                LOADING...
                <br/>
                Refresh the page if it's loading too long.
                </div>;
        }
        else {
            // Universal Constants
            const windCardinal = [ 'North', 'Northeast', 'East', 'Southeast', 'South', 'Southwest', 'West', 'Northwest' ];
            const dateOptions = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };

            // Current Variables
            var windDegreesCurrent = this.state.CurrentData.wind.deg;
            var windSpeedCurrent = this.state.CurrentData.wind.speed;
            var windGustCurrent = this.state.CurrentData.wind.gust;
            if(isNaN(windGustCurrent)){
                windGustCurrent = 'N/A';
            } else {
                windGustCurrent = windGustCurrent + ' km/h';
            }
            var humidityCurrent = this.state.CurrentData.main.humidity;
            var tempCurrent = this.state.CurrentData.main.temp;
            var feelsLikeCurrent = this.state.CurrentData.main.feels_like;
            var pressureCurrent = this.state.CurrentData.main.pressure;
            var processedDateCurrent = this.state.CurrentData.dt;

            // Historic Variables
            var hourlyHistoricWeather = this.state.HistoricData.hourly;
            var avgWindDegreesHistoric = null;
            var avgWindSpeedHistoric = null;
            var avgWindGustHistoric = null;
            var avgHumidityHistoric = null;
            var avgTempHistoric = null;
            var minTempHistoric = null;
            var maxTempHistoric = null;
            var avgPressureHistoric = null;
            var precipitationAmountHistoric = 0;
            var currentWindDirection = null;
            var historicWindDirection = null;

            /*
            * Conversions And Calculations
            */

            // Find Averages And Min/Max For Historic Data
            for (let i = 0; i < hourlyHistoricWeather.length; i++) {
                // Averages (divided after the loop)
                avgWindDegreesHistoric += hourlyHistoricWeather[i].wind_deg;
                avgWindSpeedHistoric += hourlyHistoricWeather[i].wind_speed;
                avgWindGustHistoric += hourlyHistoricWeather[i].wind_gust;
                avgHumidityHistoric += hourlyHistoricWeather[i].humidity;
                avgTempHistoric += hourlyHistoricWeather[i].temp;
                avgPressureHistoric += hourlyHistoricWeather[i].pressure;

                // Min/Max Temp
                if (i === 0) {
                    minTempHistoric = hourlyHistoricWeather[i].temp;
                    maxTempHistoric = hourlyHistoricWeather[i].temp;
                }
                else {
                    if (hourlyHistoricWeather[i].temp < minTempHistoric) {
                        minTempHistoric = hourlyHistoricWeather[i].temp;
                    }
                    else if (hourlyHistoricWeather[i].temp > maxTempHistoric) {
                        maxTempHistoric = hourlyHistoricWeather[i].temp;
                    }
                }

                // Precipitation
                if (hourlyHistoricWeather[i].hasOwnProperty('snow')) {
                    precipitationAmountHistoric += hourlyHistoricWeather[i].snow['1h'];
                }
                else if (hourlyHistoricWeather[i].hasOwnProperty('rain')) {
                    precipitationAmountHistoric += hourlyHistoricWeather[i].rain['1h'];
                }
            }
            avgWindDegreesHistoric =  Math.floor(avgWindDegreesHistoric / 24);
            avgWindSpeedHistoric = Math.round((avgWindSpeedHistoric / 24) * 100) / 100;
            if(isNaN(avgWindSpeedHistoric)){
                avgWindSpeedHistoric = 'N/A';
            } else {
                avgWindSpeedHistoric = avgWindSpeedHistoric + ' km/h';
            }
            avgWindGustHistoric = Math.round((avgWindGustHistoric / 24) * 100) / 100;
            if(isNaN(avgWindGustHistoric)){
                avgWindGustHistoric = 'N/A';
            } else {
                avgWindGustHistoric = avgWindGustHistoric + ' km/h';
            }
            avgHumidityHistoric = Math.round((avgHumidityHistoric / 24) * 100) / 100;
            if(isNaN(avgHumidityHistoric)){
                avgHumidityHistoric = 'N/A';
            } else {
                avgHumidityHistoric = avgHumidityHistoric + ' %'
            }
            avgTempHistoric = Math.round((avgTempHistoric / 24) * 100) / 100;
            if(isNaN(avgTempHistoric)){
                avgTempHistoric = 'N/A';
            } else {
                avgTempHistoric = avgTempHistoric + ' C';
            }
            avgPressureHistoric = Math.round((avgPressureHistoric / 24) * 100) / 100;
            if(isNaN(avgPressureHistoric)){
                avgPressureHistoric = 'N/A';
            } else {
                avgPressureHistoric = avgPressureHistoric + ' hPa'
            }
            precipitationAmountHistoric = Math.round( precipitationAmountHistoric * 100) /100;

            // Converts Unix Time To User Readable Format
            const dateCurrent = new Date( processedDateCurrent * 1000).toLocaleDateString( 'en-US', dateOptions, {hour12: false} );

            // Converts Degrees Obtained From Wind Direction To String Value For Wind Direction (cardinalDirections)
            windDegreesCurrent = windDegreesCurrent * 8 / 360;
            windDegreesCurrent = Math.round( windDegreesCurrent, 0 );
            windDegreesCurrent = ( windDegreesCurrent + 8 ) % 8
            currentWindDirection = windCardinal[windDegreesCurrent];
            
            avgWindDegreesHistoric = avgWindDegreesHistoric * 8 / 360;
            avgWindDegreesHistoric = Math.round( avgWindDegreesHistoric, 0 );
            avgWindDegreesHistoric = ( avgWindDegreesHistoric + 8 ) % 8
            if(isNaN(avgWindDegreesHistoric)){
                historicWindDirection = 'N/A';
            } else{
                historicWindDirection = windCardinal[avgWindDegreesHistoric];
            }

            return(
                <div id="bloc1">
                    <div className="api-Current-Box">
                        <div>
                            <h4>Current Weather</h4>
                        </div>
                        <ul className="api-Current-data">
                            <li className="Current-Weather-ItemA">Last Updated:<br /><b>{dateCurrent}</b></li>
                            <li>Curent Temp: <br/> <b>{tempCurrent} C</b></li>
                            <li>Feels Like: <br/> <b>{feelsLikeCurrent} C</b></li>
                            <li>Humidity: <br/> <b>{humidityCurrent} %</b></li>
                            <li>Wind Speed: <br/> <b>{windSpeedCurrent} km/h</b></li>
                            <li>Wind Gust: <br/> <b>{windGustCurrent}</b></li>
                            <li>Wind Direction: <br/> <b>{currentWindDirection}</b></li>
                            <li>Pressure: <br/> <b>{pressureCurrent} hPa</b></li>
                            <li className="Current-Weather-ItemB">{this.state.userCity}: <br/><b>{' (' + this.state.latWeather + ',' + this.state.lonWeather + ')'}</b></li>
                        </ul>
                    </div>

                    <div className="api-Historic-Box">
                        <div>
                            <h4>Yesterday's Weather</h4>
                        </div>
                        <ul className="api-Historic-data">
                            <li>Daily Low: <br/> <b>{minTempHistoric} C</b></li>
                            <li>Avg Temp: <br/> <b>{avgTempHistoric}</b></li>
                            <li>Daily High: <br/> <b>{maxTempHistoric} C</b></li>
                            <li>Avg Humidity: <br/> <b>{avgHumidityHistoric}</b></li>
                            <li>Avg Pressure: <br/> <b>{avgPressureHistoric}</b></li>
                            <li>Precipitation: <br/> <b>{precipitationAmountHistoric} mm</b></li>
                            <li>Avg Wind Speed: <br/> <b>{avgWindSpeedHistoric}</b></li>
                            <li>Avg Wind Gust: <br/> <b>{avgWindGustHistoric}</b></li>
                            <li>Wind Direction: <br/> <b>{historicWindDirection}</b></li>
                            <li className="Yesterday-Weather-ItemA">{this.state.userCity}: <br/><b>{' (' + this.state.latWeather + ',' + this.state.lonWeather + ')'}</b></li>
                        </ul>
                    </div>
                    <br/>
                </div>
            );
        }
    }
}
                                