import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import {nanoid} from 'nanoid';
import "../Styles/Calendar.css";
import HistoricWeatherStation from '../Components/HistoricWeatherStation';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { listUserSubscriptions } from '../graphql/queries';
import HistNotesList from '../Components/Notes/HistNotesList';
import { listUserNotes } from '../graphql/queries';
import AddNote from '../Components/Notes/AddNotes.js';
import { createUserNotes } from "../graphql/mutations";

function CalendarData() {
    //The initial state of the date
    const [date, setDate] = useState(new Date());
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [eligibleUserDevices, setEligibleUserDevices] = useState([]);

    var dateOptions = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'};

    const changeDate = (e) => {
        setDate(e);
    }

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    useEffect(() => {
        EligibleDevices();
    }, [userSubscriptions, date]);

    /**
     * Fetches all of the current user's subscriptions.
     */
    const fetchSubscriptions = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser();
            let userId = user['username'];

            const subscriptionData = await API.graphql(graphqlOperation(listUserSubscriptions, {USER_ID: userId}));
            const subscriptionDataList = subscriptionData.data.listUserSubscriptions.items;

            setUserSubscriptions(subscriptionDataList);
        } catch(error){
            //console.log(error);
        }
    }

    /**
     * Iterates through the user's subscriptions and picks out which subscriptions are eligible to be viewed by the user based on the subscription start and end dates. User can only view data from device between subscription start date and end date.
     */
    const EligibleDevices = () => {
        setEligibleUserDevices([]);
        let eligibleDevices = [];
        for (var i = 0; i < userSubscriptions.length; i++) {
            var startDate = new Date(userSubscriptions[i].START_DATE);
            var endDate   = new Date(userSubscriptions[i].END_DATE);

            if(date > startDate && date < endDate){
                eligibleDevices.push(userSubscriptions[i]);
            }
        }
        setEligibleUserDevices(eligibleDevices);
    }

    /**
     * Checks and fetches the notes into a list and returns length value to see if there notes on that date
     */
    const [noteDate, setNoteDate] = useState([]);
    var dateOptions2 = {month: 'numeric'};
    var dateOptions1 = {year: 'numeric', month: 'numeric', day: 'numeric'};
    
    useEffect(() => {
        fetchNotes();
    }, [date])

    const fetchNotes = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser ();
            let UserSubIn = user['username'];
            const savedNotesQuery = await API.graphql(graphqlOperation(listUserNotes, {UserSub: UserSubIn, NoteDate: date.toLocaleDateString("en-US", dateOptions2)+"/", limit: 2000}));
            let savedNotes = savedNotesQuery.data.listUserNotes.items;

            if(savedNotes){
                setNoteDate([]);
                let allNoteDates = [];

                for(let i=0; i < savedNotes.length; i++) {
                    allNoteDates.push(savedNotes[i].NoteDate.substring(0, 10).replace(/,| /g,''));
                }
                
                setNoteDate(allNoteDates);
            }
        } catch(error){
            console.log('error on displaying nots: ', error);
        }
    }


    /**
     * Adds Note
     */
     async function addNote(text){
        let user = await Auth.currentAuthenticatedUser ();
        let UserSubIn = user['username'];
        const newNote = {
            UserSub: UserSubIn,
            NoteID: nanoid(),
            NoteText: text,
            NoteDate: (date).toLocaleDateString("en-US", dateOptions)
        }
        await API.graphql(graphqlOperation(createUserNotes, {input: newNote}));
        fetchNotes();
    }


    return (
        <div id="Calendar" className="Cal NavbarPadding">
            <h1 className="Main-title">My Calendar </h1>
            <div id="DashboardContent">
                <div id="DashboardLeft">
                    <div className="calendar-container">
                        <Calendar 
                        onChange={changeDate} 
                        value={date}
                        onClickMonth={changeDate}
                        onActiveStartDateChange={(activeStartDate) => setDate(activeStartDate.activeStartDate)}
                        onClick={changeDate}
                        tileContent={ ({date, view}) => {
                            if(noteDate.find(x => x === date.toLocaleDateString("en-US", dateOptions1))) {
                                return (
                                    <div className="noteBox">
                                        <span className="noteCircle"></span>
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <p></p>
                                    </div>
                                )
                            }
                        }}
                        tileClassName={({date, view}) => {
                            if(noteDate.find(x => x === date.toLocaleDateString("en-US", dateOptions1))) {
                                return 'spacing'
                            }
                        }}

                        />
                    </div>
                    
                    {eligibleUserDevices.map((eligibleUserDevice) => (
                        <HistoricWeatherStation date={date.toISOString().substring(0, 10)} deviceid={eligibleUserDevice.DEVICE_IDENTIFICATION_NUMBER} deviceName={eligibleUserDevice.DEVICE_NAME}/>
                    ))}
                </div>

                <div id="DashboardRight">
                    <AddNote handleAddNote={addNote} />
                    <h2 class="calHeadNote">Notes from {date.toDateString()}</h2>
                    <HistNotesList histDate={date}/>
                </div>
            </div>
        </div>
    );
}

export default CalendarData