import { useState, useEffect } from 'react';
import DashboardMap from './DashboardMap.js';
import '../../Styles/DashboardStyles.css';
import '../../Styles/Tables.css';
import caretUp from '../../Images/caret_up_white.svg';
import caretDown from '../../Images/caret_down_white.svg';

function SelectWeatherStation({currentChosenDevice}) {
    const [clicked, setClicked] = useState(true);
    const [caretIcon, setCaretIcon] = useState([]);


    const getData = () => {
        setClicked((currState) => {
            return !currState
        });
    }

    // Changes the caret icon
    useEffect(() => {
        if(clicked){
            setCaretIcon(caretUp);
        } else{
            setCaretIcon(caretDown);
        }
	}, [clicked]);

    return (
        <div id="SelectWeatherStation">
            <div className="Click" onClick={() => getData()}>Map<img className="CaretIcon" src={caretIcon} alt="caret_icon"/></div>
            {clicked && (     
                <div>
                <DashboardMap currentChosenDevice={currentChosenDevice}/>
                </div>
            )}
        </div>
    );
}

export default SelectWeatherStation;