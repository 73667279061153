import { useEffect,useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listWeatherDataTodaysByDeviceID } from '../graphql/queries';
import '../Styles/DashboardStyles.css';
import YearlyAvgRainFall from './YearRainFall';
import caretUp from '../Images/caret_up_white.svg';
import caretDown from '../Images/caret_down_white.svg';
import '../Styles/WeatherStations.css';

function HistoricWeatherStation(props) {
    const [clicked, setClicked] = useState(false);
    const [historicFields, setHistoricFields] = useState([]);
    const [caretIcon, setCaretIcon] = useState([]);

    let deviceid = props.deviceid;
    let deviceName = props.deviceName;
    let date = props.date;

    const getData = () => {
        setClicked((currState) => {
            return !currState
        });
    }

    // Changes the caret icon
    useEffect(() => {
        if(clicked){
            setCaretIcon(caretUp);
        } else{
            setCaretIcon(caretDown);
        }
    }, [clicked]);

    useEffect(() => {
        fetchHistoricFieldData();
    }, [deviceid, date]);

    const fetchHistoricFieldData = async () => {
        try{
            // Must provide DEVICE_IDENTIFICATION_NUMBER and DATE_RECORDED in query.
            const historicFieldData = await API.graphql(graphqlOperation(listWeatherDataTodaysByDeviceID, {DEVICE_IDENTIFICATION_NUMBER: deviceid, DATE_RECORDED: date, limit: 100}));
            const historicFieldDataList = historicFieldData.data.listWeatherDataTodaysByDeviceID.items;
            setHistoricFields(historicFieldDataList);
        } catch(error){
            console.log("Unable to fetch historic field data.");
        }
    }

    //Calculations for getting the min, max, sum and averages of the data from the database
    var max = Math.max.apply(Math, historicFields.map(function(field) { return field.TEMPERATURE_OUTSIDE })).toFixed(1);
    if(isNaN(max)){
        max = 'N/A';
    }
    var min = Math.min.apply(Math, historicFields.map(function(field) { return field.TEMPERATURE_OUTSIDE })).toFixed(1);
    if(isNaN(min)){
        min = 'N/A';
    }
    var tempSum = historicFields.reduce((previous,current) => previous = previous + parseInt(current.TEMPERATURE_OUTSIDE) , 0);
    var tempAvg = (tempSum/historicFields.length).toFixed(1);
    if(isNaN(tempAvg)){
        tempAvg = 'N/A';
    }
    var humiditySum = historicFields.reduce((previous,current) => previous = previous + parseInt(current.HUMIDITY_OUTSIDE) , 0);
    var humidityAvg = (humiditySum/historicFields.length).toFixed(0);
    if(isNaN(humidityAvg)){
        humidityAvg = 'N/A';
    }
    var soiltempSum1 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_ONE) , 0);
    var soiltempAvg1 = (soiltempSum1/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg1)){
        soiltempAvg1 = 'N/A';
    }
    var soiltempSum2 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_TWO) , 0);
    var soiltempAvg2 = (soiltempSum2/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg2)){
        soiltempAvg2 = 'N/A';
    }
    var soiltempSum3 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_THREE) , 0);
    var soiltempAvg3 = (soiltempSum3/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg3)){
        soiltempAvg3 = 'N/A';
    }
    var soiltempSum4 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_FOUR) , 0);
    var soiltempAvg4 = (soiltempSum4/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg4)){
        soiltempAvg4 = 'N/A';
    }
    var totalRainFall = historicFields.reduce((previous,current) => previous = previous + parseInt(current.RAINFALL) , 0);

    try{
        return (
            <div className="WeatherStation" key={deviceid}>
                <div className="Click" onClick={() => getData()}>{deviceName}<img className="CaretIcon" src={caretIcon} alt="caret_icon"/></div>
                {clicked && (     
                    <div className="Historic-Div">
                        <h4 className="Historic-Header">{date}</h4>
                        <ul className="Historic-List">
                            <li>Max Temperature Outside: <span>{(max)}&#8451;</span></li>
                            <li>Min Temperature Outside: <span>{min}&#8451;</span></li>
                            <li>Avg. Temperature Outside: <span>{tempAvg}&#8451;</span></li>
                            <li>Avg. Humidity Outside: <span>{humidityAvg}%</span></li>
                            <li>Avg. Soil Temperature One: <span>{soiltempAvg1}&#8451;</span></li>
                            <li>Avg. Soil Temperature Two: <span>{soiltempAvg2}&#8451;</span></li>
                            <li>Avg. Soil Temperature Three: <span>{soiltempAvg3}&#8451;</span></li>
                            <li>Avg. Soil Temperature Four: <span>{soiltempAvg4}&#8451;</span></li>
                            <li>Soil Moisture One: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_ONE).toFixed(0)}%</span></li>
                            <li>Soil Moisture Two: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_TWO).toFixed(0)}%</span></li>
                            <li>Soil Moisture Three: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_THREE).toFixed(0)}%</span></li>
                            <li>Soil Moisture Four: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_FOUR).toFixed(0)}%</span></li>
                            <li>Wind Speed: <span>{parseFloat(historicFields[historicFields.length - 1].WIND_SPEED).toFixed(0)}%</span></li>
                            <li>Wind Direction: <span>{(historicFields[historicFields.length - 1].WIND_DIRECTION)}</span></li>
                            <li>Total Accumulated RainFall(Yesterday): <span>{totalRainFall}mm</span></li>
                            <li>Total Accumulated RainFall(Year): <span><YearlyAvgRainFall deviceid={deviceid}/></span></li>
                        </ul>
                    </div>
                )}
            </div>
        );
    } catch(e){
        //console.log("Error Displaying Data: ", e)
        return(
            <div className="WeatherStation" key={deviceid}>
                <div className="Click" onClick={() => getData()}>{deviceName}<img className="CaretIcon" src={caretIcon} alt="caret_icon"/></div>
                <div className="Historic-Div">
                    <p>No Data To Display</p>
                </div>
            </div>
        );
    }
}

export default HistoricWeatherStation;