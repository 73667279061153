import Notes from './Notes';
import AddNote from './AddNotes';
import './AddNotes.css';

//Formats the notes into a list
function NotesList({notes, handleAddNote, handleDeleteNote, handleFetchNote }) {
    return(
        <div className="notes-list">
            <AddNote handleAddNote = {handleAddNote}/>
            <h2 className="NotesHeader">Today's notes</h2>
            { notes.map(note => <Notes 
            id={note.NoteID} 
            text={note.NoteText} 
            dateAdded={note.NoteDate} 
            handleDeleteNote={handleDeleteNote}
            handleFetchNote={handleFetchNote}
            />)
            }
        </div>
    );
}

export default NotesList