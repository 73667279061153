import React, { useState } from 'react'
import '../Styles/Profile.css';
import {Container, Button, Form} from 'react-bootstrap';
import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';
import awsExports from "../aws-exports";
import { Link } from 'react-router-dom';

Amplify.configure(awsExports);

function Profile() {

  const [Email, setEmail] = useState('');
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Address, setAddress] = useState('');
  const [City, setCity] = useState('');
  const [Province, setProvince] = useState('');
  const [Country, setCountry] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [farmName, setFarmName] = useState('');
  const [farmLat, setFarmLat] = useState('');
  const [farmLon, setFarmLon] = useState('');

  // Assign the value from user's attributes to UseState variables
  Auth.currentAuthenticatedUser().then((userInfo) => {
    setEmail(userInfo["attributes"].email)
    setFirstName(userInfo["attributes"].given_name)
    setLastName(userInfo["attributes"].family_name)
    setAddress(userInfo["attributes"].address)
    setCity(userInfo["attributes"]["custom:city"])
    setProvince(userInfo["attributes"]["custom:province"])
    setCountry(userInfo["attributes"]["custom:country"])
    setPhoneNumber(userInfo["attributes"].phone_number)
    setFarmName(userInfo["attributes"]["custom:farm_name"])
    setFarmLat(userInfo["attributes"]["custom:lat"])
    setFarmLon(userInfo["attributes"]["custom:lon"])
  })

  // Return a form to show the user's infomation
  return (
    <Container id="main">
        <div id="Profile" className="NavbarPadding">
          <h1 className="Main-title">My Profile</h1>
          
          <div className="ProfileBoxOne">
            <h2>Basic Information</h2>
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Full Name:</Form.Label>
              <Form.Label className="fieldAnswer">{FirstName} {LastName}</Form.Label>
            </Form.Group>

            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Address:</Form.Label>
              <Form.Label className="fieldAnswer">{Address}</Form.Label>
            </Form.Group>

            <Form.Group className="formGroup">
              <Form.Label className="fieldName">City:</Form.Label>
              <Form.Label className="fieldAnswer">{City}</Form.Label>
            </Form.Group>

            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Province:</Form.Label>
              <Form.Label className="fieldAnswer">{Province}</Form.Label>
            </Form.Group>
            
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Country:</Form.Label>
              <Form.Label className="fieldAnswer">{Country}</Form.Label>
            </Form.Group>
          </div>

          <div className="ProfileBoxTwo">
            <h2>Contact Information</h2>
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Email:</Form.Label>
              <Form.Label className="fieldAnswer">{Email}</Form.Label>
            </Form.Group>

            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Phone Number:</Form.Label>
              <Form.Label className="fieldAnswer">{PhoneNumber}</Form.Label>
            </Form.Group>
          </div>

          <div className="ProfileBoxThree">
            <h2>Farm Information</h2>
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Farm Name:</Form.Label>
              <Form.Label className="fieldAnswer">{farmName}</Form.Label>
            </Form.Group>

            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Farm Location:</Form.Label>
              <Form.Label className="fieldAnswer">{farmLat}, {farmLon}</Form.Label>
            </Form.Group>
          </div>

          <div className="ProfileBoxThree">
            <Button to='/ChangeInfo' as={Link} className="changeLinkA">Change Information</Button>
            <br/>
            <Button to='/ChangePassword' as={Link} className="changeLinkB">Change Password</Button>
          </div>
        </div>
    </Container>
  )
}

export default Profile
