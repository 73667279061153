import React from 'react'
import {Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../aws-exports';
import createHistory from 'history/createBrowserHistory';
import '../Styles/DashboardStyles.css';
import '../Styles/Admin.css';
import '../Styles/Tables.css';
import {Link} from "react-router-dom";
import { listUserSubscriptions } from '../graphql/queries';
import AssignDevicesStatuses from '../Components/Subscriptions/AssignDevicesStatuses';

Amplify.configure( awsconfig );
let nextToken;

class Test extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loading: true,
      amplifyAdmin: null,
      amplifyAccounts: null,
      amplifyAccountsAll: null,
      searchBarValue: '',
      searchBy: 'First Name',
      subscriptionSearch: null
    };

    this.eventHandler = this.eventHandler.bind(this);
    this.sortTable = this.sortTable.bind(this);
  }

  eventHandler(e){
    this.setState({amplifyAccounts: this.state.amplifyAccountsAll});
    if (e.target.id === 'SearchOptions'){
      this.setState({searchBy: e.target.value}, () => {
        this.sortTable();
      });
    } else if (e.target.id === 'SearchBar'){
      this.setState({searchBarValue: e.target.value.toUpperCase()}, () => {
        this.sortTable();
      });
    } else if (e.target.id === 'active'){
      this.setState({subscriptionSearch: 'active'}, () => {
        this.sortTable();
      });
    } else if (e.target.id === 'pending'){
      this.setState({subscriptionSearch: 'actReq'}, () => {
        this.sortTable();
      });
    } else if (e.target.id === 'expired'){
      this.setState({subscriptionSearch: 'expired'}, () => {
        this.sortTable();
      });
    }
  }

  sortTable(){
    let accounts = this.state.amplifyAccounts;
    let newAccounts = [];
    let counter = 0;
    let attribute = '';

    if (this.state.searchBy === 'First Name'){
      attribute = 'First_Name';
    } else if(this.state.searchBy === 'Last Name'){
      attribute = 'Last_Name';
    } else if(this.state.searchBy === 'Farm Name'){
      attribute = 'Farm_Name';
    } else if(this.state.searchBy === 'Email'){
      attribute = 'Email';
    } else if(this.state.searchBy === 'Phone Number'){
      attribute = 'Phone_Number';
    }

    for(let i = 0; i < accounts.length; i++){
      if (accounts[i][attribute].toUpperCase().includes(this.state.searchBarValue)){
        if(this.state.subscriptionSearch == null){
            newAccounts[counter] = accounts[i];
            counter ++;
        } else {
          if(accounts[i][this.state.subscriptionSearch] > 0){
            newAccounts[counter] = accounts[i];
            counter ++;
          }
        }
      }
    }
    this.setState({amplifyAccounts: newAccounts});
  }
  
  // Request the API to pull the users from the database
  async componentDidMount( limit ) {
    let apiName = 'AdminQueries';
    let path = '/listUsers';
    let myInit = {
      queryStringParameters: {
        "UserPoolId": "us-east-2_JcMzZ72vQ",
        "limit": limit,
        "token": nextToken
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }

    const { NextToken, ...rest } = await API.get( apiName, path, myInit );
    nextToken = NextToken;
    this.setState( {
      amplifyAdmin: rest,
      loading: false
    } );

    // Assign the values to the amplifyArray
    const amplifyArray = this.state.amplifyAdmin.Users.map( test => {
      const data = {};
        for(let i = 0; i < test.Attributes.length; i ++){
          if (test.Attributes[i].Name === 'given_name'){
            data.First_Name = test.Attributes[i].Value;
          } else if (test.Attributes[i].Name === 'family_name'){
            data.Last_Name = test.Attributes[i].Value;
          } else if (test.Attributes[i].Name === 'phone_number'){
            data.Phone_Number = test.Attributes[i].Value;
          } else if (test.Attributes[i].Name === 'email'){
            data.Email = test.Attributes[i].Value;
          } else if (test.Attributes[i].Name === 'custom:farm_name'){
            data.Farm_Name = test.Attributes[i].Value;
          } else if (test.Attributes[i].Name === 'custom:lon'){
            data.Farm_City = test.Attributes[i].Value;
          } else if (test.Attributes[i].Name === 'custom:lat'){
            data.Farm_Address = test.Attributes[i].Value;
          }
        }
        if (!data.Farm_Name){
          data.Farm_Name = '';
        }
        data.User_Sub = test.Username;
        data.active = 0;
        data.expired = 0;
        data.actReq = 0;
      return data;
    } );

    const subs = [];
    for(let i = 0; i < amplifyArray.length; i++){
      let thisRecord = await API.graphql(graphqlOperation(listUserSubscriptions, {USER_ID: amplifyArray[i].User_Sub}));
      subs[i] = thisRecord.data.listUserSubscriptions;
    }
    for(let i = 0; i < subs.length; i ++){
      AssignDevicesStatuses(subs[i].items);
      for(let y = 0; y < subs[i].items.length; y ++){
        if(subs[i].items[y].STATUS === "Active"){
          amplifyArray[i].active++;
        } else if(subs[i].items[y].STATUS === "Expired"){
          amplifyArray[i].expired++;
        }  else if(subs[i].items[y].STATUS === "Expiring" || subs[i].items[y].STATUS === "Pending"){
          amplifyArray[i].actReq++;
        }
      }
    }

    this.setState({ amplifyAccountsAll: amplifyArray });
    this.setState({ amplifyAccounts: amplifyArray });
    
  }

  renderTotals(){
    let totalActive = 0;
    let totalExpired = 0;
    let totalActReq = 0;
    for(let i = 0; i < this.state.amplifyAccountsAll.length; i ++){
      totalActive = totalActive + this.state.amplifyAccountsAll[i].active;
      totalExpired = totalExpired + this.state.amplifyAccountsAll[i].expired;
      totalActReq = totalActReq + this.state.amplifyAccountsAll[i].actReq;
    }

    return(
      <div className="Pending">
        <span className="PendingRequests" id='activeContainer'><p id='active' onClick={ this.eventHandler }>{totalActive} Active Subscriptions</p></span>
        <span className="PendingRequests" id='pendingContainer'><p id='pending' onClick={ this.eventHandler }>{totalActReq} Pending/Expiring Requests</p></span>
        <span className="PendingRequests" id='expiredContainer'><p id='expired' onClick={ this.eventHandler }>{totalExpired} Expired Subscriptions</p></span>
      </div>
    )
  }

  // Render a table with users' information
  renderTable() {
    return this.state.amplifyAccounts.map((account, index) => {
      return(
        <tr key={"user "+index}>
          <td>{account.First_Name} {account.Last_Name}</td>
          <td>{account.Phone_Number}</td>
          <td>{account.Email}</td>
          <td>{account.Farm_Name}</td>
          <td>{account.Farm_Address}, {account.Farm_City}</td>
          <td><span class="circleC">{account.active}</span><span class="circleB">{account.actReq}</span><span class="circleD">{account.expired}</span></td>
          <td>
            <Link to={{pathname: "/ViewUserInfo", state: 
              {userSub: account.User_Sub,
              firstName: account.First_Name,
              lastName: account.Last_Name,
              phoneNumber: account.Phone_Number,
              email: account.Email,
              farmName: account.Farm_Name,
              farmAddress: account.Farm_Address,
              farmCity: account.Farm_City}}}><button><span>View User</span></button></Link>
          </td>
        </tr>
      )
    })
  }

  // Redirect normal users if the they try to access the Admin page
  render() {
    function refreshPage() {
      window.location.reload();
    }
    async function CheckAdmin(){
      const user = await Auth.currentSession();
      var groups = user.accessToken.payload["cognito:groups"];
      if(groups != 'admin'){
        const history = createHistory();
        window.open( 'https://www.ennsbrothers.com/' );
        history.push( '' );
        refreshPage();
      }
    }
    CheckAdmin()

    // Check the status of the Amplify
    if (this.state.loading) {
      return <div>LOADING...</div>
    }

    if (!this.state.amplifyAdmin) {
      return <div>CURRENTLY LOADING STATION</div>
    }

    if (!this.state.amplifyAccounts) {
      return <div>LOADING API...</div>
    }    

    // Return a table with users' information
    return(
      <div className="NavbarPadding">
        <h1 className="Main-title">Admin</h1>
        <div className="Requests">
        { this.renderTotals() }
          <div className="FilterAndSearch">
            <form id='adminForm'>
              <label>Filter By: </label>
              <select id="SearchOptions" onChange={ this.eventHandler }>
                <option>First Name</option>
                <option>Last Name</option>
                <option>Farm Name</option>
                <option>Email</option>
                <option>Phone Number</option>
              </select>
              <input type="text" id="SearchBar" placeholder="Search" onKeyUp={ this.eventHandler }></input>
            </form>
          </div>
        </div>

        <table className="adminTable" id="UserTable">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Farm Name</th>
              <th>Farm Location</th>
              <th>Subscriptions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            { this.renderTable() }
          </tbody>
        </table>
      </div>
    )
  }
}

export default Test;