import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from "react";
import {Auth} from 'aws-amplify';
import CurrentSubscriptions from "../Components/CurrentSubscriptions";
import '../Styles/Subscribe.css';
import { useState } from 'react';
import SubscribeMap from '../Components/Map/SubscribeMap.js';
import xIcon from '../Images/x_button_white.svg';

export default function Subscribe() {
	const [allAvailableDevices, setAllAvailableDevices] = useState([]);
	const [allSelectedDevices, setAllSelectedDevices] = useState([]);

	let history = useHistory();

	useEffect(() => {
        coordCheck();
    }, [])

	const coordCheck = async () => {
		const user = await Auth.currentAuthenticatedUser ();
		if ( user['attributes']['custom:lat'] === '' || user['attributes']['custom:lon'] === '' || Math.abs(user['attributes']['custom:lat']) > 90 || Math.abs(user['attributes']['custom:lon']) > 180){
			await Auth.updateUserAttributes(user, {
                'custom:lat': "49.8951",
                'custom:lon': "-97.1384"
              })
			window.location.reload();
		}
	}

	/**
	 * Removes device from allSelectedDevices state and adds it to allAvailableDevices state 
	 * which turns the device marker to black on the subscribe map and removes it from 'selected stations' section on this page.
	 * 
	 * @param {Object} device - device object that the user selected
	 * @param {string} device.DEVICE_IDENTIFICATION_NUMBER - DeviceId of the selected device 
	 */
	const handleDeviceCancel = (device) => {
		let newAllSelectedDevices;
		newAllSelectedDevices = allSelectedDevices.filter(item => item.DEVICE_IDENTIFICATION_NUMBER !== device.DEVICE_IDENTIFICATION_NUMBER);
		setAllSelectedDevices([...newAllSelectedDevices]);
		device.DEVICE_NAME = device.DEVICE_IDENTIFICATION_NUMBER;
		allAvailableDevices.push(device);
		setAllAvailableDevices([...allAvailableDevices]);
    }

	/**
	 * If user has at least one device selected, redirects to Confirmation page and passes the array of all selected devices.
	 */
    function toConfirmation() {
		if(allSelectedDevices.length !== 0){
			history.push({
				pathname: '/Confirmation',
				state: allSelectedDevices
			})
		}
    }

	return (
		<div className="NavbarPadding" id="Subscribe" style={{padding: "0px 10px 0px 20px"}}>
			<h1 class="maintitle">Subscribe</h1>
			<div className="SubscribeTop">
				<div id="SubscribeWeatherStation">
					<div className="SubscribeHeader">Select Weather Stations</div>
						<SubscribeMap 
							allAvailableDevices={allAvailableDevices}
							setAllAvailableDevices={setAllAvailableDevices}
							allSelectedDevices={allSelectedDevices}
							setAllSelectedDevices={setAllSelectedDevices}
						/>
						<div className="SelectedStationsInfoBox">
				<h2 className="SubscribeStation">Selected Stations ({allSelectedDevices.length})</h2>

				{/* Template for the subscribed item */}
				{/* <div className="SubscriptionItem">
					<span class="circleA"></span>
					<p>Station ID: 00000000fxf8fx</p>
				</div> */}
				<div style={{display: "flex", flexWrap: "wrap", gap: "10px", margin: "10px 0px 10px 0px"}}>
				{allSelectedDevices.map((selectedDevice) => (
                    <div className="SubscriptionItem" key={selectedDevice.DEVICE_IDENTIFICATION_NUMBER}>
						<div className="SubLeft">
							<span className="circleA"></span>
							<p>Station ID: {selectedDevice.DEVICE_IDENTIFICATION_NUMBER}</p>
						</div>
						<div className="SubRight">
							{/* <p>Station Name:</p>
							<input type="text" className="DeviceName" onChange={(e) => handleDeviceNameChange(selectedDevice, e)} maxLength="40" placeholder={selectedDevice.DEVICE_IDENTIFICATION_NUMBER}/> */}
							<button className="CancelDeviceButton" onClick={() => handleDeviceCancel(selectedDevice)}><img src={xIcon} alt="Cancel"/></button>
						</div>
					</div>
                ))}
				</div>

				<div className="SubBottom">
					<div className="SubscribePrice">
						<p>Estimated Total: <b>${(allSelectedDevices.length * 1250).toFixed(2)}</b> </p>
						<small><p>Based on number of stations selected</p></small>
					</div>
					<div className="SubBox">
						<Button onClick={toConfirmation} className="SubscribeButtonsD">Proceed</Button>
					</div>
				</div>
			</div>
				</div>
				<CurrentSubscriptions />
			</div>

			


			{/*<Elements stripe={stripeTestPromise}>
				<PaymentForm />
			</Elements>*/}
		</div>
	)
}