import createHistory from 'history/createBrowserHistory'

const refreshPage = ()=>{

    window.location.reload();

 }

export default function EnvCanRedirect() {

const history = createHistory();

  return (

    window.open('https://weather.gc.ca/map_e.html?layers=radar&zoom=-1&center=49.88,-97.15'),

   

    history.push(''),

    refreshPage()

  );

 

}