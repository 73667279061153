import React from "react";
import Fields from './Fields';
import '../Styles/DashboardStyles.css';
import '../Styles/Tables.css';
import '../Styles/Modal.css';

function AddField() {
  /*
  state = {
    isOpen: false
  };

  modalOn = () => this.setState({ isOpen: true });
  modalOff = () => this.setState({ isOpen: true });

  render() {
    function refreshPage()
    {
      window.location.reload();
    }
    const history = createHistory();
    function test(){
       history.push( '' )
        refreshPage()   
       
    }
    */
    
  return (   
    <div className="NavbarPadding">
      <h1 className="Main-title">Farm Settings</h1>
      <Fields />
      {/*
      <div className="FieldContainer">
        <form>
          <label type="text">Client Name: </label>
          <input type="text"/>
          <br/>
          <label type="text">Farm Name: </label>
          <input type="text"/>
          <br/>
          <label type="text">Field Name: </label>
          <input type="text"/>
          <br/>

          <input className="Center" type="submit" value="Add Field"></input>
        </form>
      </div>
      */}
    </div>
  );
}

export default AddField;