import { API, graphqlOperation, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import HistNotesList from '../Components/Notes/HistNotesList';
import { listWeatherDataTodaysByDeviceID, listUserSubscriptions } from '../graphql/queries';
import { listUserNotes } from '../graphql/queries';

/**
 * @description This function contains the function to fetch that data from the database. Contains the filter function for setting the 
 *              Device ID which is used for the query. Displays the notes based on the date selected from the calendar and handles
 *              when the delete button is clicked.
 *
 * @return {*} Returns a JSX table containing the fetched data along with the notes that were created on that day. The filter for the
 *              Weather stations is also included here.
 */
function HistoricDataForField(){
    const [fields, setFields] = useState([]);
    const [id, setID] = useState([]);
    const [deviceID, setDeviceID] = useState('');
    let currentDate = new Date();
    var dateOptions2 = {year: 'numeric', month: 'numeric', day: 'numeric'};


    //Grabs the date state the was passed in from the calendar page and converts it to utc time and shortens the string
    const location = useLocation();
    let history = useHistory();
    const {date} = location.state;
    let verifiedAccess = false;
    let histDate;
    
    // Checks if any date data was passed in. If no date data was passed in, redirect user back to home page. If date data was passed in, set verifiedAccess to true (allows page to render).
    // Prevents user from direcctly accessing the page via link.
    if(typeof date === 'undefined'){
        history.push("/");   
    }
    else{
        verifiedAccess = true;
        histDate = date.toISOString().substring(0, 10);
    }
    
    //inital state of the notes
    const [histNotes, setHistNotes] = useState([]);

    //When Device ID state changes, page will load and run the fetchFieldData function
    useEffect(() => {
        fetchFieldData();
    }, [deviceID]);

    //When the page is loaded, will run the fetchDeviceID function
    useEffect(() => {
        fetchDeviceID();
    }, []);

    //When the page is loaded, Will retrieve the data from local storage and updates the histNotes state
    useEffect(() => {
        fetchNotes();
    }, [])

    const fetchNotes = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser ();
            let UserSubIn = user['username'];
            const savedNotes = await API.graphql(graphqlOperation(listUserNotes, {UserSub: UserSubIn, NoteDate: date.toLocaleDateString("en-US", dateOptions2), limit: 1000}));

            if(savedNotes){
                setHistNotes(savedNotes.data.listUserNotes.items);
            }
        } catch(error){
            console.log('error on displaying nots: ', error);
        }
    }

    //To store the notes into local storage
    useEffect(() => {
        //Give it a key,value. Value being what is being stored
        localStorage.setItem('calendar-notes-data', JSON.stringify(histNotes))
    }, [histNotes])
    
    //The fetch query for querying the graphql API and updating the ID state
    const fetchDeviceID = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser ();
            let UserSubIn = user['username'];
            const fieldData = await API.graphql(graphqlOperation(listUserSubscriptions, {USER_ID: UserSubIn}));
            const fieldDataList = fieldData.data.listUserSubscriptions.items;
            let active = []
            for(var i = 0; i < fieldDataList.length; i++){
                if(fieldDataList[i].STATUS === 'Active' || fieldDataList[i].STATUS === 'Expiring'){
                    active[i] = fieldDataList[i];
                }
            }
            setID(active);
        } catch(error){
            
        }
    }

    //The fetch query for querying the database based on yesterdays date and the device ID that was selected
    const fetchFieldData = async () => {
        try{
            // Must provide DEVICE_IDENTIFICATION_NUMBER and DATE_RECORDED in query.
            const fieldData = await API.graphql(graphqlOperation(listWeatherDataTodaysByDeviceID, {DEVICE_IDENTIFICATION_NUMBER: deviceID, DATE_RECORDED: histDate, limit: 100}));

            const fieldDataList = fieldData.data.listWeatherDataTodaysByDeviceID.items;
           
            setFields(fieldDataList);
        } catch(error){
            
        }
    }

    //Handles the click event when you click on the delete button
    function deleteHistNote(histid){
        
        const newHistNotes = histNotes.filter((note)=> note.id !== histid);
        setHistNotes(newHistNotes);
    }

    //Calculations for determining the max, min, sum and averages of the data from yesterdays items
    var max = Math.max.apply(Math, fields.map(function(field) { return field.TEMPERATURE_OUTSIDE })).toFixed(1);
    if(isNaN(max)){
        max = 'N/A';
    }
    var min = Math.min.apply(Math, fields.map(function(field) { return field.TEMPERATURE_OUTSIDE })).toFixed(1);
    if(isNaN(min)){
        min = 'N/A';
    }
    var tempSum = fields.reduce((previous,current) => previous = previous + parseInt(current.TEMPERATURE_OUTSIDE) , 0);
    var tempAvg = (tempSum/fields.length).toFixed(1);
    if(isNaN(tempAvg)){
        tempAvg = 'N/A';
    }
    var humiditySum = fields.reduce((previous,current) => previous = previous + parseInt(current.HUMIDITY_OUTSIDE) , 0);
    var humidityAvg = (humiditySum/fields.length).toFixed(0);
    if(isNaN(humidityAvg)){
        humidityAvg = 'N/A';
    }
    var soiltempSum1 = fields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_ONE) , 0);
    var soiltempAvg1 = (soiltempSum1/fields.length).toFixed(0);
    if(isNaN(soiltempAvg1)){
        soiltempAvg1 = 'N/A';
    }
    var soiltempSum2 = fields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_TWO) , 0);
    var soiltempAvg2 = (soiltempSum2/fields.length).toFixed(0);
    if(isNaN(soiltempAvg2)){
        soiltempAvg2 = 'N/A';
    }
    var soiltempSum3 = fields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_THREE) , 0);
    var soiltempAvg3 = (soiltempSum3/fields.length).toFixed(0);
    if(isNaN(soiltempAvg3)){
        soiltempAvg3 = 'N/A';
    }
    var soiltempSum4 = fields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_FOUR) , 0);
    var soiltempAvg4 = (soiltempSum4/fields.length).toFixed(0);
    if(isNaN(soiltempAvg4)){
        soiltempAvg4 = 'N/A';
    }
    var totalRainFall = fields.reduce((previous,current) => previous = previous + parseInt(current.RAINFALL) , 0);

    //Handles the click event for clicking the Device ID filter button. Will udpated the deviceId state with the value that was clicked
    const handleClick = (e) => {
        setDeviceID(e.target.value);
    }

    //maps through the id variable and fetching only the DeviceID's
    const devices = id.map((item, index) => item.DEVICE_IDENTIFICATION_NUMBER);
    //Removes Duplicate valus for displaying
    const filteredID = devices.filter((item, index) => devices.indexOf(item) === index);

    return(
        <div>
        {verifiedAccess && (
        <div className="NavbarPadding">
            <h1 style={{margin: "0px", fontSize: "50px"}}>Historic Data</h1>
            <div>
            <table align="left" className="filterid">
                <thead>
                    <tr>
                        <th>Device ID's</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredID.map((id) => (
                        <tr>
                            <td><p>Device ID: <br /><button className="idfilter" onClick={handleClick} value={id}>{id}</button></p></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            {fields.filter((field, index) => index < 1).map((field) => (
                <table className="field-data">
                <thead>
                <tr>
                    <th colSpan="2">{histDate}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan="2">Selected Device: {field.DEVICE_IDENTIFICATION_NUMBER}</td>
                </tr>
                <tr>
                    <td>Max Temperature OutSide: {max}&#8451;</td>
                    <td>Min Temperature OutSide: {min}%</td>
                </tr>
                <tr>
                    <td>Mean Temperature OutSide: {tempAvg}&#8451;</td>
                    <td>Mean Humidity OutSide: {humidityAvg}%</td>
                </tr>
                <tr>
                    <td>Soil Temperature One: {soiltempAvg1}&#8451;</td>
                    <td>Soil Temperature Two: {soiltempAvg2}&#8451;</td>
                </tr>
                <tr>
                    <td>Soil Temperature Three: {soiltempAvg3}&#8451;</td>
                    <td>Soil Temperature Four: {soiltempAvg4}&#8451;</td>
                </tr>
                <tr>
                    <td>Soil Moisture One: {parseFloat(field.SOIL_MOISTURE_ONE).toFixed(0)}%</td>
                    <td>Soil Moisture Two: {parseFloat(field.SOIL_MOISTURE_TWO).toFixed(0)}%</td>
                </tr>
                <tr>
                    <td>Soil Moisture Three: {parseFloat(field.SOIL_MOISTURE_THREE).toFixed(0)}%</td>
                    <td>Soil Moisture Four: {parseFloat(field.SOIL_MOISTURE_FOUR).toFixed(0)}%</td>
                </tr>
                <tr>
                    <td>Wind Speed: {parseFloat(field.WIND_SPEED).toFixed(0)}%</td>
                    <td>Wind Direction: {(field.WIND_DIRECTION)}</td>
                </tr>
                <tr>
                    <td colSpan="2">Total Accumulative Rainfall: {totalRainFall}mm</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2">Last updated: {field.DATE_RECORDED}</td>
                    </tr>
                    <tr>
                        <td colSpan="2">Last accessed: {currentDate.toLocaleString()}</td>
                    </tr>   
                </tfoot>
                </table>
            ))}
            <div>
                <HistNotesList histnotes={histNotes}
                handleDeleteHistNote={deleteHistNote}
                histDate={histDate}
                handleFetchNote={fetchNotes}/>
            </div>
        </div>
        )}
        </div>
    )
}

export default HistoricDataForField;