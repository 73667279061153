import createHistory from 'history/createBrowserHistory';
/**
 * @description refeshPage: Refresh The Page On Click
 */
function refreshPage()
{
  window.location.reload();
}
/**
 * @description  EnnsRedirect: Redirects The User To The Enns Brothers Website And Then Routes The User Back To The Home Page
 * @export  EnnsRedirect
 * @returns {*}  
 */
export default function EnnsRedirect() {
  const history = createHistory();
  return (
    window.open( 'https://www.ennsbrothers.com/' ),
    history.push( '' ),
    refreshPage()
  );
}