import React, { useState } from 'react'
import '../Styles/ChangeInfo.css';
import {Container, Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";

// Return the form for the user to enter the new password
function ChangePassword() {
  let history = useHistory();

  const [passwordCurrent, setCurrentPassword] = useState('');
  const [passwordNew, setNewPassword] = useState('');
  const [passwordConfirm, setConfirmPassword] = useState('');

// Check the confirm password and update the user pasword with the value in formState
  function updatePassword() {
    if (passwordNew === passwordConfirm) {
      Auth.currentAuthenticatedUser().then(user => {
        return Auth.changePassword(user, passwordCurrent, passwordNew);
      })
        .then(date => {
          alert('Sucessfully Changed Password');
          history.push("/Profile");
        })
        .catch(e => {      
          // TODO: Add an if statement for catching the specfic error else display other error
          alert(e.message);
        })
    } else {
      alert('Confirm Password Does Not Match');
    }
  }

  return (
    <Container id="main">
        <div id="ChangePassword" className="NavbarPadding">
          <h1 class="maintitle">Change Password</h1>
          <Form className="changePassword">
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Current Password: </Form.Label>
              <Form.Control className="fieldInput" type="password" placeholder="Current Password" name="current" 
              value={passwordCurrent} onChange={e => setCurrentPassword(e.target.value)} />
            </Form.Group>
            
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">New Password: </Form.Label>
              <Form.Control className="fieldInput" type="password" placeholder="New Password" name="new" 
              value={passwordNew} onChange={e => setNewPassword(e.target.value)} />
            </Form.Group>
            
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Confirm Password: </Form.Label>
              <Form.Control className="fieldInput" type="password" placeholder="Confirm Password" name="confirm" 
              value={passwordConfirm} onChange={e => setConfirmPassword(e.target.value)} />
            </Form.Group>
            
            <Button onClick={updatePassword}><span className="registerButton">Confirm</span></Button>
            
          </Form>
        </div>
    </Container>
  )
}

export default ChangePassword
