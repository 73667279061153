import React, { useState } from 'react';
import { MenuItems } from "./MenuItems";
import './MainNavbar.css'
import { Link } from 'react-router-dom';
import image from './logo.png';
import xMark from '../../Images/x_button_white.svg';
import hamMenu from '../../Images/hamburger_menu_white.svg';

function MainNavBar() {
        const [click, setClicked] = useState(false);

        const toggleNav = () => {
            setClicked((state) => {
                return !state
            });
        }

        return(    
        <div>     
            {/* FOR TESTING PURPOSES UNCOMMENT THIS TO HAVE A ON/OFF NAVGATION BAR*/}
            {/*
            <label for="toggle">Collapse Button</label>
            <input type="checkbox" id="toggle"></input>
            */}

            <div className={`Navbar ${click ? "open":""}`}>
                <div className="Box">
                    <div>
                        <div className="profile">
                            <button className="navIconA" onClick={toggleNav}>
                                {click ? <img className="navIconA" src={xMark} alt="x"></img>:<img className="navIconA" src={hamMenu} alt="x"></img>}
                            </button>
                            <img className="logo" src={image} alt="Enns Brother"/>
                        </div>

                        <ul className="Navbar-list top">
                            {MenuItems.map((item, index) => {
                                if (index <= 3) {
                                    return(
                                        <Link to={item.route} className={item.cName} onClick={toggleNav}>
                                            <li key={index} className="list-item">
                                                    <div className="icon"><span>{item.icon}</span> {item.title}</div>
                                            </li>
                                        </Link>
                                    )
                            }})}
                        </ul>
                    </div>
                    <ul className="Navbar-list bottom">
                        {MenuItems.map((item, index) => {
                            if (index > 3) {
                                return(
                                    <Link to={item.route} className={item.cName} onClick={toggleNav}>
                                        <li key={index} className="list-item">
                                                <div className="icon"><span>{item.icon}</span> {item.title}</div>
                                        </li>
                                    </Link>
                                )
                        }})}
                    </ul>
                </div>
            </div>
        </div> 
        )
    }

export default MainNavBar