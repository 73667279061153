/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWeatherDataToday = /* GraphQL */ `
  query GetWeatherDataToday(
    $DATE_RECORDED: String!
    $DEVICE_IDENTIFICATION_NUMBER: String!
  ) {
    getWeatherDataToday(
      DATE_RECORDED: $DATE_RECORDED
      DEVICE_IDENTIFICATION_NUMBER: $DEVICE_IDENTIFICATION_NUMBER
    ) {
      DATE_RECORDED
      DEVICE_IDENTIFICATION_NUMBER
      SOIL_MOISTURE_ONE
      SOIL_MOISTURE_TWO
      SOIL_MOISTURE_THREE
      SOIL_MOISTURE_FOUR
      SOIL_TEMPERATURE_ONE
      SOIL_TEMPERATURE_TWO
      SOIL_TEMPERATURE_THREE
      SOIL_TEMPERATURE_FOUR
      TEMPERATURE_OUTSIDE
      WIND_SPEED
      RAINFALL
      HUMIDITY_OUTSIDE
      WIND_DIRECTION
    }
  }
`;
export const listWeatherDataTodays = /* GraphQL */ `
  query ListWeatherDataTodays(
    $filter: TableWeatherDataTodayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeatherDataTodays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        DATE_RECORDED
        DEVICE_IDENTIFICATION_NUMBER
        SOIL_MOISTURE_ONE
        SOIL_MOISTURE_TWO
        SOIL_MOISTURE_THREE
        SOIL_MOISTURE_FOUR
        SOIL_TEMPERATURE_ONE
        SOIL_TEMPERATURE_TWO
        SOIL_TEMPERATURE_THREE
        SOIL_TEMPERATURE_FOUR
        TEMPERATURE_OUTSIDE
        WIND_SPEED
        RAINFALL
        HUMIDITY_OUTSIDE
        WIND_DIRECTION
      }
      nextToken
    }
  }
`;
export const listWeatherDataTodaysByDeviceID = /* GraphQL */ `
  query ListWeatherDataTodaysByDeviceID(
    $DEVICE_IDENTIFICATION_NUMBER: String
    $DATE_RECORDED: String
    $filter: TableWeatherDataTodayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeatherDataTodaysByDeviceID(
      DEVICE_IDENTIFICATION_NUMBER: $DEVICE_IDENTIFICATION_NUMBER
      DATE_RECORDED: $DATE_RECORDED
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        DATE_RECORDED
        DEVICE_IDENTIFICATION_NUMBER
        SOIL_MOISTURE_ONE
        SOIL_MOISTURE_TWO
        SOIL_MOISTURE_THREE
        SOIL_MOISTURE_FOUR
        SOIL_TEMPERATURE_ONE
        SOIL_TEMPERATURE_TWO
        SOIL_TEMPERATURE_THREE
        SOIL_TEMPERATURE_FOUR
        TEMPERATURE_OUTSIDE
        WIND_SPEED
        RAINFALL
        HUMIDITY_OUTSIDE
        WIND_DIRECTION
      }
      nextToken
    }
  }
`;
export const getDeviceLocation = /* GraphQL */ `
  query GetDeviceLocation($DEVICE_IDENTIFICATION_NUMBER: String!) {
    getDeviceLocation(
      DEVICE_IDENTIFICATION_NUMBER: $DEVICE_IDENTIFICATION_NUMBER
    ) {
      DEVICE_IDENTIFICATION_NUMBER
      Latitude
      Longitude
    }
  }
`;
export const listDeviceLocations = /* GraphQL */ `
  query ListDeviceLocations(
    $filter: TableDeviceLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        DEVICE_IDENTIFICATION_NUMBER
        Latitude
        Longitude
      }
      nextToken
    }
  }
`;
export const getUserNotes = /* GraphQL */ `
  query GetUserNotes($UserSub: String!, $NoteDate: String!) {
    getUserNotes(UserSub: $UserSub, NoteDate: $NoteDate) {
      NoteDate
      UserSub
      NoteID
      NoteText
    }
  }
`;
export const listUserNotes = /* GraphQL */ `
  query ListUserNotes(
    $UserSub: String
    $NoteDate: String
    $filter: TableUserNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotes(
      UserSub: $UserSub
      NoteDate: $NoteDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        NoteDate
        UserSub
        NoteID
        NoteText
      }
      nextToken
    }
  }
`;
export const getUserSubscriptions = /* GraphQL */ `
  query GetUserSubscriptions(
    $USER_ID: String!
    $DEVICE_IDENTIFICATION_NUMBER: String!
  ) {
    getUserSubscriptions(
      USER_ID: $USER_ID
      DEVICE_IDENTIFICATION_NUMBER: $DEVICE_IDENTIFICATION_NUMBER
    ) {
      DEVICE_IDENTIFICATION_NUMBER
      USER_ID
      DEVICE_NAME
      START_DATE
      END_DATE
    }
  }
`;
export const listUserSubscriptions = /* GraphQL */ `
  query ListUserSubscriptions(
    $USER_ID: String
    $filter: TableUserSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(
      USER_ID: $USER_ID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        DEVICE_IDENTIFICATION_NUMBER
        USER_ID
        DEVICE_NAME
        START_DATE
        END_DATE
      }
      nextToken
    }
  }
`;
export const listAllUserSubscriptions = /* GraphQL */ `
  query ListAllUserSubscriptions(
    $filter: TableUserSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        DEVICE_IDENTIFICATION_NUMBER
        USER_ID
        DEVICE_NAME
        START_DATE
        END_DATE
      }
      nextToken
    }
  }
`;
export const getCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns = /* GraphQL */ `
  query GetCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns($id: String!) {
    getCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(id: $id) {
      id
      name
      email
    }
  }
`;
export const listCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns = /* GraphQL */ `
  query ListCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(
    $filter: TableCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnnsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateYfv7bu4mjvc2hiyy2q4inirqiyEnns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
      }
      nextToken
    }
  }
`;
