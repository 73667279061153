import createHistory from 'history/createBrowserHistory';
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class Popup extends Component {
  state = {
    isOpen: false
  };

  modalOn = () => this.setState({ isOpen: true });
  modalOff = () => this.setState({ isOpen: true });

  render() {
    function refreshPage()
    {
      window.location.reload();
    }
    const history = createHistory();
    function test(){
       history.push( '' )
        refreshPage()   
       
    }
    
    return (
       
      <>
     
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
        </div>
        <Modal show={this.modalOn} onHide={this.modalOff}>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={test}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Popup;