import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../Styles/DashboardStyles.css';
import UserInfo from "../Components/UserInfo";
import { createUserSubscriptions } from '../graphql/mutations';
import {Auth, API, graphqlOperation} from 'aws-amplify';
import { listUserSubscriptions } from '../graphql/queries';
import exclamationIcon from '../Images/exclamation_icon.svg';

function Confirmation() {
    const [email, setEmail] = useState('');

    Auth.currentAuthenticatedUser().then((userInfo) => {
        setEmail(userInfo["attributes"].email)
    })

    let history = useHistory();

    const selectedDevices = history.location.state;
    let confirmedUser = false;

    // Checks if any selected devices were passed in. If no selected devices were passed in, redirect user back to home page. If selected devices were passed in, set confirmedUser to true (allows page to render).
    if(typeof selectedDevices === 'undefined'){
        history.push("/");   
    }
    else{
        confirmedUser = true;
    }

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Email Data for the confirmation
        fetch("https://8sxjhg1f4l.execute-api.us-east-2.amazonaws.com/sendEmail", {
            mode: "no-cors",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify( {
                senderName: "weather@ennsbros.com",
                senderEmail: email,
                message: "Thank you for subscribing to weather stations and choosing Enns Brother. Your weather stations are currently being approved by our cordinators. For more details you can contact us at CONTACT. ",
                date: new Date(),
            })
        })

        // Variables used for the create query.
        let user = await Auth.currentAuthenticatedUser();
        let userId = user['username'];

        const subscriptionData = await API.graphql(graphqlOperation(listUserSubscriptions, {USER_ID: userId}));
        const subscriptionDataList = subscriptionData.data.listUserSubscriptions.items;

        let newSubscription = [];
        // Loops through array of device IDs that the user wants to subscribe to and creates an object for each device. Each object then gets added to newSubscription array which is then put into the createUserSubscriptions query as input.
        for (var i = 0; i < selectedDevices.length; i++) {
            let alreadyExists = false;

            // Checks if the user is already subscribed to the device
            for (var j = 0; j < subscriptionDataList.length; j++) {
                if(selectedDevices[i].DEVICE_IDENTIFICATION_NUMBER === subscriptionDataList[j].DEVICE_IDENTIFICATION_NUMBER){
                    alreadyExists = true;
                }
            }

            if(!alreadyExists){
                let deviceSubscription = {
                    DEVICE_IDENTIFICATION_NUMBER: selectedDevices[i].DEVICE_IDENTIFICATION_NUMBER,
                    USER_ID: userId,
                    DEVICE_NAME: selectedDevices[i].DEVICE_NAME,
                    START_DATE: "Pending",
                    END_DATE: "Pending"
                }
                newSubscription.push(deviceSubscription);
            }
        }
        try{
            if(newSubscription.length > 0){
                // A batch create query. Takes an array of objects as input and creates multiple records in database at once.
                await API.graphql(graphqlOperation(createUserSubscriptions, {input: newSubscription}));
            }
        }
        catch(error){
            //console.log(error);
        }

        history.push("/ThankYou")
    };

    function returnSubscribe() {
        history.push("/Subscribe");
    }

    return (
        <div>
        {confirmedUser && (
            <div className="NavbarPadding" id="confirm">
            <h1 className="Main-title">Confirmation</h1>
            <div className="SubscriptionsInfoBox">
                <img src={exclamationIcon} alt="" />
                <p>This clearly fake piece of text explains subscriptions and what should happen once the user confirms the subscriptions.</p>
            </div>
            <div className="GeneralInfoBox">
                <h3>Your Info</h3>
                <UserInfo/>
            </div>
            <div className="GeneralInfoBox">
                <h2>Selected Stations ({selectedDevices.length})</h2>
                {selectedDevices.map((device) => (
                    <div className="ConfirmSubscriptionItem" key={device.DEVICE_IDENTIFICATION_NUMBER}>
                        <p><b>Station Name:</b> {device.DEVICE_NAME}</p><br/>
					    {/* <p><b>Station ID:</b> {device.DEVICE_IDENTIFICATION_NUMBER}</p> */}
				    </div>
                ))}

                <p>Estimated Total: <b>${(selectedDevices.length * 1250).toFixed(2)}</b> </p>
                <small>Based on number of stations selected</small>

                <div className="SubscribeButtonBox">
                    <Button className="SubscribeButtonsC" onClick={returnSubscribe}>Go Back</Button>
                    <Button className="SubscribeButtonsB" onClick={handleSubmit}>Confirm</Button>
                </div>
            </div>
        </div>
        )}
        </div>
    );
}

export default Confirmation;