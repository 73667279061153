import React, { useEffect, useState } from 'react';
import '../../Styles/Map.css';
//import { RutherfordEds, RutherfordGeorgeSouthQtr, RutherfordGrossStanSouth, RutherfordRyansGordon } from '../../RutherfordFarmFields.js';
import '../API/env.local'
import GetFarmCoordinates from './FarmCoordinatesGeocoding';
import FetchDeviceLocations from './FetchDeviceLocations.js';
import blackMarker from '../../Images/device_marker_1.svg';
import greenMarker from '../../Images/device_marker_green.svg';
import yellowMarker from '../../Images/device_marker_yellow.svg';
import redMarker from '../../Images/device_marker_red.svg';
import homeIcon from '../../Images/home_icon.svg';
import { useHistory } from 'react-router-dom';

// Main google maps code derived from https://www.youtube.com/watch?v=WZcxJGmLbSo
//Google Map Api
import {
	GoogleMap,
	useLoadScript,
	Marker,
	InfoWindow,
	//Polyline,
} from "@react-google-maps/api";

const libraries = ["places"];

//How big the map is
const mapContainerStyle = {
	border: "1px solid lightgray",
	borderRadius: "0px",
	maxHeight: "650px",
	minHeight: "490px",
	maxWidth: "800px"
};

//Main function that displays the map and everything else
export default function DashboardMap({currentChosenDevice}) {
	const [selectedAvailableDevice, setSelectedAvailableDevice] = useState(null);
	const [selectedSubscribedDevice, setSelectedSubscribedDevice] = useState(null);
	const [allAvailableDevices, setAllAvailableDevices] = useState([]);
	const [allSubscribedDevices, setAllSubscribedDevices] = useState([]);
	const [farmCoordinates, setFarmCoordinates] = useState({ lat: 49.8951, lng: -97.1384 });
	const [mapCenter, setMapCenter] = useState({ lat: 49.8951, lng: -97.1384 });


	let history = useHistory();

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: "AIzaSyBMAeEfyIAsp5pcHktATWWO-D0aALkMZpo",
		libraries,
	});

	useEffect(() => {
		// Fetches locations of all available devices (the black markers) and user subscribed devices (red, yellow, green, blue markers) and sets state for them.
		FetchDeviceLocations(setAllAvailableDevices, setAllSubscribedDevices);

		// GetFarmCoordinates is a function that gets the farm coordinates and the map gets centered on those coordinates.
		GetFarmCoordinates(setFarmCoordinates, setMapCenter);
	}, []);

	useEffect(() => {
	if(currentChosenDevice){
		let chosenDevice = allSubscribedDevices.find(device => device.DEVICE_IDENTIFICATION_NUMBER === currentChosenDevice);
		let newCenterCoordinates = {
			lat: 49.8951, 
			lng: -97.1384
		}

		if(typeof chosenDevice !== 'undefined'){
			newCenterCoordinates.lat = parseFloat(chosenDevice.Latitude);
			newCenterCoordinates.lng = parseFloat(chosenDevice.Longitude);
	
			setMapCenter(newCenterCoordinates);
		}
	}

	}, [currentChosenDevice]);

	/**
	 * Returns a colored marker icon image based on the status of the device.
	 * 
	 * @param {String} deviceStatus - Current subscription status of the device.
	 * @returns Colored marker icon.
	 */
	const GetMarkerIcon = (deviceStatus) => {
		switch (deviceStatus) {
			case "Active":
				return greenMarker;
			case "Expired":
				return redMarker;
			case "Expiring":
				return yellowMarker;
			case "Pending":
				return yellowMarker;
			default:
				return yellowMarker;
		}
	}

	//Error messages if theres a certain error
	if (loadError) return "Error loading maps";
	if (!isLoaded) return "Loading Maps";

	return (
		<div>
			{/* Script fixes the google error where "google is not defined" */}
			<script async defer
				src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMAeEfyIAsp5pcHktATWWO-D0aALkMZpo&callback=initMap">
			</script>
			
			{/* The main google maps division */}
			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				zoom={12}
				options={{ streetViewControl: false, fullscreenControl: false }}
				center={mapCenter}
			>
				{/* Poyline used for the coordinates to create a plotted part of the map. Currently polyline coordinates are brought in via functions from RutherfordFarmFields.js file. */}
				{/* Polyline code derived from https://developers.google.com/maps/documentation/javascript/examples/polyline-simple */}
				{/* <Polyline
					path={RutherfordGeorgeSouthQtr()}
					geodesic={true}
					options={{
						strokeColor: "#ff2527",
						strokeOpacity: 0.75,
						strokeWeight: 2,
						icons: [
							{
								offset: "0",
								repeat: "20px"
							}
						]
					}}
				/>
				<Polyline
					path={RutherfordEds()}
					geodesic={true}
					options={{
						strokeColor: "#ff2527",
						strokeOpacity: 0.75,
						strokeWeight: 2,
						icons: [
							{
								offset: "0",
								repeat: "20px"
							}
						]
					}}
				/>
				<Polyline
					path={RutherfordGrossStanSouth()}
					geodesic={true}
					options={{
						strokeColor: "#ff2527",
						strokeOpacity: 0.75,
						strokeWeight: 2,
						icons: [
							{
								offset: "0",
								repeat: "20px"
							}
						]
					}}
				/>
				<Polyline
					path={RutherfordRyansGordon()}
					geodesic={true}
					options={{
						strokeColor: "#ff2527",
						strokeOpacity: 0.75,
						strokeWeight: 2,
						icons: [
							{
								offset: "0",
								repeat: "20px"
							}
						]
					}}
				/> */}
				{/* Generating markers for all weather devices that are non subscribed and available to the user to subscribe (black markers)*/}
				{allAvailableDevices.map((device) => (
					<Marker
						key={device.DEVICE_IDENTIFICATION_NUMBER}
						position={{ lat: parseFloat(device.Latitude), lng: parseFloat(device.Longitude) }}
						onClick={() => {
							setSelectedAvailableDevice(device)
						}
						}
						icon={{
							url: blackMarker,
							scaledSize: new window.google.maps.Size(30, 30)
						}}
					/>
				))}

				{/* Generating markers for all weather devices that the user is subscribed to (green, red, yellow markers)*/}
				{allSubscribedDevices.map((device) => (
					<Marker
						key={device.DEVICE_IDENTIFICATION_NUMBER}
						position={{ lat: parseFloat(device.Latitude), lng: parseFloat(device.Longitude) }}
						onClick={() => {
							setSelectedSubscribedDevice(device)
						}
						}
						icon={{
							url: GetMarkerIcon(device.STATUS),
							scaledSize: new window.google.maps.Size(30, 30)
						}}
					/>
				))}

				{/* Adds home icon to indicate user's farm location */}
				<Marker
					key={farmCoordinates.lon}
					icon={{
						url: homeIcon,
						scaledSize: new window.google.maps.Size(30, 30)
					}}
					position={{ lat: farmCoordinates.lat, lng: farmCoordinates.lng }}
				/>

				{/* Information window for when an available weather device (black marker) is clicked. */}
				{selectedAvailableDevice ? (<InfoWindow position={{ lat: parseFloat(selectedAvailableDevice.Latitude), lng: parseFloat(selectedAvailableDevice.Longitude) }}
					onCloseClick={() => { setSelectedAvailableDevice(null); }}>
					<div>
						<h4> Station ID: {selectedAvailableDevice.DEVICE_IDENTIFICATION_NUMBER} </h4>
						<p> Coordinates: {parseFloat(selectedAvailableDevice.Latitude)}, {parseFloat(selectedAvailableDevice.Longitude)}</p>
						<button onClick={() => {history.push("/Subscribe")}}>Subscribe</button>
					</div>
				</InfoWindow>) : null}

				{/* Information window for when a subscribed weather device (colored marker) is clicked. */}
				{selectedSubscribedDevice ? (<InfoWindow position={{ lat: parseFloat(selectedSubscribedDevice.Latitude), lng: parseFloat(selectedSubscribedDevice.Longitude) }}
					onCloseClick={() => { setSelectedSubscribedDevice(null); }}>
					<div>
						<h3>{selectedSubscribedDevice.DEVICE_NAME}</h3>
						<p> Station ID: {selectedSubscribedDevice.DEVICE_IDENTIFICATION_NUMBER} </p>
						<p> Coordinates: {parseFloat(selectedSubscribedDevice.Latitude)}, {parseFloat(selectedSubscribedDevice.Longitude)}</p>
						<p> <b>{selectedSubscribedDevice.STATUS}</b> </p>
					</div>
				</InfoWindow>) : null}
			</GoogleMap>

		</div>


	);

}