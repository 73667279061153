import { useEffect,useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listWeatherDataTodaysByDeviceID } from '../graphql/queries';
import { updateUserSubscriptions } from '../graphql/mutations';
import '../Styles/DashboardStyles.css';
import '../Styles/WeatherStations.css';
import YearlyAvgRainFall from './YearRainFall';
import caretUp from '../Images/caret_up_white.svg';
import caretDown from '../Images/caret_down_white.svg';
import pencilIcon from '../Images/pencil_icon.svg';

function WeatherStation({deviceInfo, setCurrentChosenDevice}) {
    const [clicked, setClicked] = useState(false);
    const [fields, setCurrentFields] = useState([]);
    const [historicFields, setHistoricFields] = useState([]);
    const [caretIcon, setCaretIcon] = useState([]);

    const [changingName, setChangingName] = useState(false);
    const [deviceName, setDeviceName] = useState(deviceInfo.DEVICE_NAME);

    let deviceid = deviceInfo.DEVICE_IDENTIFICATION_NUMBER;
    let userId = deviceInfo.USER_ID;

    const getData = () => {
        setClicked((currState) => {
            return !currState
        });
    }

    // Changes the caret icon
    useEffect(() => {
        if(clicked){
            setCaretIcon(caretUp);
            setCurrentChosenDevice(deviceid);
        } else{
            setCaretIcon(caretDown);
            setCurrentChosenDevice('');
        }
    }, [clicked]);

    useEffect(() => {
        fetchCurrentFieldData();
        fetchHistoricFieldData();
    }, [deviceid]);

    let currDate = new Date();
    let currentDate = new Date().toISOString().substring(0, 10);
    let yesterdayDate = currDate.setDate(currDate.getDate() - 1);
    yesterdayDate = new Date(yesterdayDate).toISOString().substring(0,10);
    
    const fetchCurrentFieldData = async () => {
        try{
            // Must provide DEVICE_IDENTIFICATION_NUMBER and DATE_RECORDED in query.
            const currentFieldData = await API.graphql(graphqlOperation(listWeatherDataTodaysByDeviceID, {DEVICE_IDENTIFICATION_NUMBER: deviceid, DATE_RECORDED: currentDate, limit: 100})); 
            const currentFieldDataList = currentFieldData.data.listWeatherDataTodaysByDeviceID.items;
            setCurrentFields(currentFieldDataList);
        } catch(error){
           console.log("Unable to fetch current field data.");
        }
    }

    const fetchHistoricFieldData = async () => {
        try{
            // Must provide DEVICE_IDENTIFICATION_NUMBER and DATE_RECORDED in query.
            const historicFieldData = await API.graphql(graphqlOperation(listWeatherDataTodaysByDeviceID, {DEVICE_IDENTIFICATION_NUMBER: deviceid, DATE_RECORDED: yesterdayDate, limit: 100}));
            const historicFieldDataList = historicFieldData.data.listWeatherDataTodaysByDeviceID.items;
            setHistoricFields(historicFieldDataList);
        } catch(error){
            console.log("Unable to fetch historic field data.");
        }
    }

    //Calculations for getting the min, max, sum and averages of the data from the database
    var max = Math.max.apply(Math, historicFields.map(function(field) { return field.TEMPERATURE_OUTSIDE })).toFixed(1);
    if(isNaN(max)){
        max = 'N/A';
    } else {
        max = max + ' C';
    }
    var min = Math.min.apply(Math, historicFields.map(function(field) { return field.TEMPERATURE_OUTSIDE })).toFixed(1);
    if(isNaN(min)){
        min = 'N/A';
    } else {
        min = min + ' C';
    }
    var tempSum = historicFields.reduce((previous,current) => previous = previous + parseInt(current.TEMPERATURE_OUTSIDE) , 0);
    var tempAvg = (tempSum/historicFields.length).toFixed(1);
    if(isNaN(tempAvg)){
        tempAvg = 'N/A';
    } else {
        tempAvg = tempAvg + ' C';
    }
    var humiditySum = historicFields.reduce((previous,current) => previous = previous + parseInt(current.HUMIDITY_OUTSIDE) , 0);
    var humidityAvg = (humiditySum/historicFields.length).toFixed(0);
    if(isNaN(humidityAvg)){
        humidityAvg = 'N/A';
    } else {
        humidityAvg = humidityAvg + ' %';
    }
    var soiltempSum1 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_ONE) , 0);
    var soiltempAvg1 = (soiltempSum1/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg1)){
        soiltempAvg1 = 'N/A';
    } else {
        soiltempAvg1 = soiltempAvg1 + ' C';
    }
    var soiltempSum2 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_TWO) , 0);
    var soiltempAvg2 = (soiltempSum2/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg2)){
        soiltempAvg2 = 'N/A';
    } else {
        soiltempAvg2 = soiltempAvg2 + ' C';
    }
    var soiltempSum3 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_THREE) , 0);
    var soiltempAvg3 = (soiltempSum3/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg3)){
        soiltempAvg3 = 'N/A';
    } else {
        soiltempAvg3 = soiltempAvg3 + ' C';
    }
    var soiltempSum4 = historicFields.reduce((previous,current) => previous = previous + parseInt(current.SOIL_TEMPERATURE_FOUR) , 0);
    var soiltempAvg4 = (soiltempSum4/historicFields.length).toFixed(0);
    if(isNaN(soiltempAvg4)){
        soiltempAvg4 = 'N/A';
    } else {
        soiltempAvg4 = soiltempAvg4 + ' C';
    }
    var totalRainFall = historicFields.reduce((previous,current) => previous = previous + parseInt(current.RAINFALL) , 0);


    // Device Name Changing -------------------------------------------------------------------------------------------------
    let nameChangeInput;
    let displayDeviceName;

    /**
     * Makes input element lose focus when user presses 'Enter' key.
     */
    const handleKeyDownDeviceName = (e) => {
        if (e.keyCode === 13) {
          e.target.blur();
        }
    }

    /**
     * Toggles device name input element on/off.
     */
    const toggleNameEdit = () => {
        setChangingName(!changingName);
    }

    /**
     * When input element loses focus, updates user subscription with a new device name.
     */
    const handleDeviceNameBlur = async (e) => {
        toggleNameEdit();
        if(e.target.value !== deviceName){
            setDeviceName(e.target.value);
            await API.graphql(graphqlOperation(updateUserSubscriptions, {input: {DEVICE_IDENTIFICATION_NUMBER: deviceid, USER_ID: userId, DEVICE_NAME: e.target.value}}));
        }
        if(e.target.value.trim() === ''){
            setDeviceName(deviceid);
            await API.graphql(graphqlOperation(updateUserSubscriptions, {input: {DEVICE_IDENTIFICATION_NUMBER: deviceid, USER_ID: userId, DEVICE_NAME: deviceid}}));
        }
    }

    if(changingName){
        nameChangeInput = <input type="text" className="DeviceName" maxLength="40" onBlur={(e) => handleDeviceNameBlur(e)} defaultValue={deviceName} style={{verticalAlign: "middle"}} onKeyDown={(e) => handleKeyDownDeviceName(e)} autoFocus/>;
    }
    if(!changingName){
        displayDeviceName = deviceName;
    }

    let stationHeader = (<div className="Click" onClick={() => getData()}>
                        <div>
                        <img className="pencilIcon" src={pencilIcon} alt="pencil_icon" style={{width: "17px", height: "17px", cursor: "pointer", marginRight: "5px", verticalAlign: "middle"}} onClick={() => {getData(); toggleNameEdit();}}/>
                        {nameChangeInput}{displayDeviceName}
                        <img className="CaretIcon" src={caretIcon} alt="caret_icon" style={{verticalAlign: "middle"}}/>
                        </div>
                        </div>);

    try{
        return (
            <div className="WeatherStation" key={deviceid}>
                {stationHeader}
                {clicked && (     
                <div>
                    <div className="CurrentStation">
                        <div className="Current-Station-Header">
                            <h4 className="Current-Station-Title">Current</h4>
                            <h4 className="Header-Align-Right">Last Updated: {currentDate}</h4>
                        </div>
                        <ul className="Current-Station-List">
                            <li>Current Temp: <br/><b>{fields[fields.length - 1].TEMPERATURE_OUTSIDE} C</b></li>
                            <li>Humidity: <br/> <b>{fields[fields.length - 1].HUMIDITY_OUTSIDE} %</b></li>
                            <li>Wind Speed: <br/> <b>{parseFloat(fields[fields.length - 1].WIND_SPEED).toFixed(0)} km/h</b></li>
                            <li>Wind Direction: <br/> <b>{fields[fields.length - 1].WIND_DIRECTION}</b></li>
                            <li>Soil Temperature One: <span>{parseFloat(fields[fields.length - 1].SOIL_TEMPERATURE_ONE).toFixed(0)} C</span></li>
                            <li>Soil Temperature Two: <span>{parseFloat(fields[fields.length - 1].SOIL_TEMPERATURE_TWO).toFixed(0)} C</span></li>
                            <li>Soil Temperature Three: <span>{parseFloat(fields[fields.length - 1].SOIL_TEMPERATURE_THREE).toFixed(0)} C</span></li>
                            <li>Soil Temperature Four: <span>{parseFloat(fields[fields.length - 1].SOIL_TEMPERATURE_FOUR).toFixed(0)} C</span></li>
                            <li>Soil Moisture One: <span>{parseFloat(fields[fields.length - 1].SOIL_MOISTURE_ONE).toFixed(0)} %</span></li>
                            <li>Soil Moisture Two: <span>{parseFloat(fields[fields.length - 1].SOIL_MOISTURE_TWO).toFixed(0)} %</span></li>
                            <li>Soil Moisture Three: <span>{parseFloat(fields[fields.length - 1].SOIL_MOISTURE_THREE).toFixed(0)} %</span></li>
                            <li>Soil Moisture Four: <span>{parseFloat(fields[fields.length - 1].SOIL_MOISTURE_FOUR).toFixed(0)} %</span></li>
                            <li className="Current-Station-Item-A">RainFall: <span>{fields[fields.length - 1].RAINFALL} mm</span></li>
                        </ul>
                    </div>
                    <br/>
                    <div className="CurrentStation">
                        <div className="Yesterday-Station-Header">
                            <h4>Yesterday</h4>
                        </div>
                        <ul className="Yesterday-Station-List">
                            <li>Max Temperature Outside: <span>{(max)}</span></li>
                            <li>Min Temperature Outside: <span>{min}</span></li>
                            <li>Avg. Temperature Outside: <span>{tempAvg}</span></li>
                            <li>Avg. Humidity Outside: <span>{humidityAvg}</span></li>
                            <li>Avg. Soil Temperature One: <span>{soiltempAvg1}</span></li>
                            <li>Avg. Soil Temperature Two: <span>{soiltempAvg2}</span></li>
                            <li>Avg. Soil Temperature Three: <span>{soiltempAvg3}</span></li>
                            <li>Avg. Soil Temperature Four: <span>{soiltempAvg4}</span></li>
                            <li>Soil Moisture One: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_ONE).toFixed(0)} %</span></li>
                            <li>Soil Moisture Two: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_TWO).toFixed(0)} %</span></li>
                            <li>Soil Moisture Three: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_THREE).toFixed(0)} %</span></li>
                            <li>Soil Moisture Four: <span>{parseFloat(historicFields[historicFields.length - 1].SOIL_MOISTURE_FOUR).toFixed(0)} %</span></li>
                            <li>Wind Speed: <span>{parseFloat(historicFields[historicFields.length - 1].WIND_SPEED).toFixed(0)} km/h</span></li>
                            <li>Wind Direction: <span>{(historicFields[historicFields.length - 1].WIND_DIRECTION)}</span></li>
                            <li>Total Accumulated RainFall(Yesterday): <span>{totalRainFall} mm</span></li>
                            <li>Total Accumulated RainFall(Year): <span><YearlyAvgRainFall deviceid={deviceid}/></span></li>
                        </ul>
                    </div>
                </div>)}
            </div>
        );
    } catch(e){
        //console.log("Error Displaying Data: ", e)
        return(
            <div className="WeatherStation" key={deviceid}>
                {stationHeader}
                <div className="Historic-Div">
                    <p>No Data To Display</p>
                </div>
            </div>
        );
    }
}

export default WeatherStation;