import createHistory from 'history/createBrowserHistory';
/**
 * @description refeshPage: Refresh The Page On Click
 */
function refreshPage() {
  window.location.reload();
}
/**
 * @description  JDeereRedirect: Redirects The User To The JohnDeere Website And Then Routes The User Back To The Home Page
 * @export  JDeereRedirect
 * @returns {*}  
 */
export default function JDeereRedirect() {
  const history = createHistory();
  return (
    window.open( 'https://myjohndeere.deere.com/mjd/my/login' ),
    history.push( '' ),
    refreshPage()
  );
}