/**
 * Assigns a status to each user subscription passed in the array based on the end date.
 * 
 * @param {Array} subscriptionDataList - An array of user subscription objects.
 */
const AssignDevicesStatuses = (subscriptionDataList) => {
    for (var i = 0; i < subscriptionDataList.length; i++) {
        if(subscriptionDataList[i].END_DATE === "Pending"){
            subscriptionDataList[i].STATUS = "Pending";
        }
        else{
            let endDate = new Date(subscriptionDataList[i].END_DATE);
            let today = new Date();
    
            // Finds the difference in days between today and subscription end date
            let difference = endDate.getTime() - today.getTime();
            let days = Math.ceil(difference / (1000 * 3600 * 24));

            if(days > 30){
                subscriptionDataList[i].STATUS = "Active";
            }
            if(days < 30 && days > 0){
                subscriptionDataList[i].STATUS = "Expiring";
            }
            if(days <= 0){
                subscriptionDataList[i].STATUS = "Expired";
            }
        }
    }
    return subscriptionDataList;
}

export default AssignDevicesStatuses;