import { yupResolver } from '@hookform/resolvers/yup';
  import Amplify  from 'aws-amplify';
  import { Button, Container, Form } from 'react-bootstrap';
  import { useForm } from 'react-hook-form';
  import * as yup from 'yup';
  import awsExports from '../aws-exports';
  import '../Styles/Register.css';
  Amplify.configure( awsExports );
  /**
   * @description schema: Provides Form Validation For The Add Field Form.
   */
  let schema = yup.object().shape( {
    // Validation: Client Name Must Not Be Null.
    CLIENT_NAM: yup.string()
      .required( 'PLEASE ENTER YOUR CLIENT NAME!' ),
    // Validation: Farm Name Must Not Be Null.
    FARM_NAME: yup.string()
      .required( "PLEASE ENTER YOUR FARM NAME!" ),
    // Validation: Field Name Must Not Be Null.
    FIELD_NAME: yup.string()
      .required( "PLEASE ENTER YOUR FIELD NAME!" ),
  });
  
  /**
   * @description addField: Uses Data From User Input And Sends To DynamoDB Using A Function And API
   * @param  {{"FID":"FID"} fieldAliases - Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"FARM_NAME"} "FARM_NAME" - Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"ORG_ID"} "ORG_ID"- Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"FARM_ID"} "FARM_ID"- Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"CLIENT_NAM"} "CLIENT_NAM"- Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"CLIENT_ID"} "CLIENT_ID"- Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"FIELD_ID"} "FIELD_ID"- Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"POLYGONTYP"} "POLYGONTYP"- Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {"FIELD_NAME"}} "FIELD_NAME" - Data Saved From FormState Is Now Stored In A Variable And Used In Http Request.
   * @param  {[{attributes:{FID:formState.FID} - Accepts User Input And Stores In A Variable
   * @param  {formState.FARM_NAME} FARM_NAME - Accepts User Input And Stores In A Variable
   * @param  {formState.ORG_ID} ORG_ID - Accepts User Input And Stores In A Variable
   * @param  {formState.FARM_ID} FARM_ID - Accepts User Input And Stores In A Variable
   * @param  {formState.CLIENT_NAM} CLIENT_NAM - Accepts User Input And Stores In A Variable
   * @param  {formState.CLIENT_ID} CLIENT_ID - Accepts User Input And Stores In A Variable
   * @param  {formState.FIELD_ID} FIELD_ID - Accepts User Input And Stores In A Variable
   * @param  {formState.POLYGONTYP} POLYGONTYP - Accepts User Input And Stores In A Variable
   * @param  {formState.FIELD_NAME}} FIELD_NAME - Accepts User Input And Stores In A Variable
   */
  
  async function addField() {
    alert( 'Sucessfully Added Farm' );
  }
  // Sets The Initial Form State To Empty
  const formState = {
    displayFieldName: 'g',
    FID: '',
    ORG_ID: '',
    FARM_ID: '',
    CLIENT_NAM: '',
    CLIENT_ID: '',
    FARM_NAME: '',
    FIELD_ID: '',
    POLYGONTYP: '',
    FIELD_NAME: '',
  };
  /**
   * @description Updates The State Of The Form Based On User Input
   * @param {*} key 
   * @param {*} value
   */
  function updateFormState( key, value ) {
    formState[ key ] = value;
  }
  /**
   * @description Fields: Validates The Form For Errors
   * If Errors Are Detected It Will Display Errors On Screen
   * Resets Form After Successful Submition
   */
  function Fields() {
    const {
      register,
      handleSubmit,
      reset,
      formState: {
        errors
      },
    } = useForm( {
      resolver: yupResolver( schema ),
    } );
    // On Submit Will Send Data Defined Above To The AddFieldAPI Using The /addFieldLAMBDA Function
    const onSubmit = () => {
   
      addField();
      reset();
    };
    return (
      <Container id="mains">
        <div id="addField">
        <Form classname="name" onSubmit={handleSubmit(onSubmit)}>
          <h2>Add Field</h2>
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Client Name</Form.Label>
              <Form.Control placeholder="Client Name"							
              {...register('CLIENT_NAM', {
                                  required: 'Client Name is required',
                              })} 
                onChange={e => updateFormState('CLIENT_NAM', e.target.value)} />
                {errors.CLIENT_NAM?.message && <p>{errors.CLIENT_NAM?.message}</p>}
            </Form.Group>
  
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Farm Name</Form.Label>
              <Form.Control placeholder="Farm Name" 	
              {...register('FARM_NAME', {
                                  required: 'Farm Name is required',
                              })}
                onChange={e => updateFormState('FARM_NAME', e.target.value)} />
                 {errors.FARM_NAME?.message && <p>{errors.FARM_NAME?.message}</p>}
            </Form.Group>
  
            <Form.Group className="formGroup">
              <Form.Label className="fieldName">Field Name: </Form.Label>
              <Form.Control placeholder="Field Name" 
              {...register('FIELD_NAME', {
                                  required: 'Field Name is required',
                              })}
                onChange={e => updateFormState('FIELD_NAME', e.target.value)} />
                {errors.FIELD_NAME?.message && <p>{errors.FIELD_NAME?.message}</p>}
            </Form.Group>
            <Button type='submit'><span className="registerButton">Add Field</span></Button>
          </Form>
        </div>
      </Container>
    );
  }
  export default Fields;
  
  
  