import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { listWeatherDataTodaysByDeviceID } from '../graphql/queries';
/**
 * @description This function fetches from the database and is filtered by the year. Calculates the sum of the Rain fall data for the
 *              year and puts it into a div
 * @export
 * @param {*} {deviceid} Passed in value used to filter the query by device ID
 * @return {*} A div element containing the sum of the Accumulative Rain Fall of the year
 */
export default function YearlyAvgRainFall({deviceid}){
    const [fields, setFields] = useState([]);

    //when the page is loaded, will run the fetchFieldData function. Will load if the state of the device ID is changed
    useEffect(() => {
        fetchFieldData();
    }, [deviceid]);

    //Grabs the current date, and shortens the string to just grab the year
    let year = new Date().toISOString().substring(0,4);

    /**
     * @description fetches the data using the graphQL API. Filtered by the year and the device ID. Updates the fields state to contain
     *              the fetched items.
     *
     */
    const fetchFieldData = async () => {
        try{
            // Pulls in records from the past year. The current limit of 400 will become a problem as a station will have more more than 400 records a year at one point. Will probably need pagination(?).
            const fieldData = await API.graphql(graphqlOperation(listWeatherDataTodaysByDeviceID, {DEVICE_IDENTIFICATION_NUMBER: deviceid, DATE_RECORDED: year, limit: 400}));
            const fieldDataList = fieldData.data.listWeatherDataTodaysByDeviceID.items;
           
            setFields(fieldDataList);
        } catch(error){
           
        }
    }

    //Calculates the sum of the Rain Fall data from the year
    var totalRainFall = fields.reduce((previous,current) => previous = previous + parseInt(current.RAINFALL) , 0);

    return(
        <div>
            {totalRainFall}mm
        </div>
    )
}