import { useState } from 'react';
import './AddNotes.css';


//function for adding notes
function AddNote({handleAddNote}) {
    const [noteText, setNoteText] = useState('');
    const charLimit = 250;

    //function that handles when state gets updated with new note
    function handleChange(e){
        //Prevents a user from writing more than 100 letters
        if(charLimit - e.target.value.length >= 0){
            //updates the state with what the user has typed in
            setNoteText(e.target.value);
        }
    }

    //function that handles the onClick event
    function handleSave(){
        //If input is blank, then it will not be able to be added
        if(noteText.trim().length > 0){
            handleAddNote(noteText);
            //This makes it so the next note added does not have the same text from the previous one
            setNoteText('');
        }
    }

    return(
        <div>
            <h2 className="NotesHeader">New Note</h2>
            <div className="note">
                <textarea rows="8" cols="10" 
                placeholder="Type to add notes..."
                onChange={handleChange}
                value={noteText}
                ></textarea>
                <div className='note-footer'>
                    <small>{charLimit - noteText.length} Remaining</small>
                </div>
            </div>
            <button className="save" onClick={handleSave}><i class="bi bi-plus">Submit</i></button>
        </div>
    )
}

export default AddNote