import HistNotes from "./HistNotes";
import { API, graphqlOperation, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { listUserNotes } from '../../graphql/queries';

//Formats the notes into a list
function HistNotesList({histDate}) {
    const [histNotes, setHistNotes] = useState([]);
    var dateOptions2 = {year: 'numeric', month: 'numeric', day: 'numeric'};

    useEffect(() => {
        fetchNotes();
    },[histDate]);

    const fetchNotes = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser ();
            let UserSubIn = user['username'];
            const savedNotes = await API.graphql(graphqlOperation(listUserNotes, {UserSub: UserSubIn, NoteDate: histDate.toLocaleDateString("en-US", dateOptions2), limit: 1000}));

            if(savedNotes){
                setHistNotes(savedNotes.data.listUserNotes.items);
            }
        } catch(error){
            console.log('error on displaying nots: ', error);
        }
    }

    //Handles the click event when you click on the delete button
    function deleteHistNote(histid){
        const newHistNotes = histNotes.filter((note)=> note.id !== histid);
        setHistNotes(newHistNotes);
    }

    return(
        //loop through the notes array
        <div className="notes-list">
            {histNotes.map(note => <HistNotes histid={note.NoteID} 
            text={note.NoteText} 
            dateAdded={note.NoteDate} 
            handleDeleteHistNote={deleteHistNote}
            handleFetchNote={fetchNotes}/>)
            }
        </div>
    )
}

export default HistNotesList;