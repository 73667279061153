import createHistory from 'history/createBrowserHistory'

const refreshPage = ()=>{

    window.location.reload();

 }

export default function WeatherNetRedirect() {

const history = createHistory();

  return (

    window.open('https://www.theweathernetwork.com/ca/map/radar?lat=49.8538&lng=-97.1747'),

   

    history.push(''),

    refreshPage()

  );

 

}