import { listDeviceLocations, listUserSubscriptions } from '../../graphql/queries';
import { API, graphqlOperation, Auth } from 'aws-amplify';

/**
 * Fetches locations for all user's subscribed and non subscribed weather devices.
 */
 const FetchDeviceLocations = async (setAllAvailableDevices, setAllSubscribedDevices) => {
    try {
        // Fetch all device coordinates.
        const deviceLocation = await API.graphql(graphqlOperation(listDeviceLocations));
        let deviceLocationList = deviceLocation.data.listDeviceLocations.items;

        // Fetch all user subscriptions.
        let user = await Auth.currentAuthenticatedUser();
        let userId = user['username'];
        const subscriptions = await API.graphql(graphqlOperation(listUserSubscriptions, { USER_ID: userId }));
        let subscriptionsList = subscriptions.data.listUserSubscriptions.items;

        let deviceWithCoordinates;
        // Loops through user subscriptions and adds coordinates and status to each subscription and removes the device from deviceLocationList array. 
        for (let i = 0; i < subscriptionsList.length; i++) {
            // Check if subscribed device has a location
            deviceWithCoordinates = deviceLocationList.find(item => item.DEVICE_IDENTIFICATION_NUMBER === subscriptionsList[i].DEVICE_IDENTIFICATION_NUMBER);

            // If location is found for device then add coordinates to the subscription and remove device from deviceLocationList array.
            if (typeof deviceWithCoordinates !== 'undefined') {
                subscriptionsList[i].Latitude = deviceWithCoordinates.Latitude;
                subscriptionsList[i].Longitude = deviceWithCoordinates.Longitude;
                deviceLocationList = deviceLocationList.filter(item => item.DEVICE_IDENTIFICATION_NUMBER !== subscriptionsList[i].DEVICE_IDENTIFICATION_NUMBER);

                // Assigns status to each subscription based on the end date. Statuses are used for color coding the devices on the map.
                if(subscriptionsList[i].END_DATE === "Pending"){
                    subscriptionsList[i].STATUS = "Pending";
                }
                else{
                    let endDate = new Date(subscriptionsList[i].END_DATE);
                    let today = new Date();
            
                    // Finds the difference in days between today and subscription end date
                    let difference = endDate.getTime() - today.getTime();
                    let days = Math.ceil(difference / (1000 * 3600 * 24));

                    if(days > 30){
                        subscriptionsList[i].STATUS = "Active";
                    }
                    if(days < 30 && days > 0){
                        subscriptionsList[i].STATUS = "Expiring";
                    }
                    if(days <= 0){
                        subscriptionsList[i].STATUS = "Expired";
                    }
                }
            }
            else {
                // If device in the subscription doesn't match any devices in the deviceLocationList array it gets deleted from subscriptionsList.
                subscriptionsList = subscriptionsList.filter(item => item.DEVICE_IDENTIFICATION_NUMBER !== subscriptionsList[i].DEVICE_IDENTIFICATION_NUMBER);
            }
        }
        setAllAvailableDevices(deviceLocationList);
        setAllSubscribedDevices(subscriptionsList);
    } catch (error) {
        console.log("Unable to fetch device locations.");
    }

}

export default FetchDeviceLocations;