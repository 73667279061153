import { API, graphqlOperation, Auth } from "aws-amplify";
import { deleteUserNotes } from "../../graphql/mutations";

async function DeleteNote(dateAdded, handleFetchNote){
    var user = await Auth.currentAuthenticatedUser();
    var userSubIn = user['username'];
    await API.graphql(graphqlOperation(deleteUserNotes, {input: {UserSub: userSubIn, NoteDate: dateAdded}}));
    handleFetchNote();
}

export default DeleteNote;