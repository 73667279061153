import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp
} from '@aws-amplify/ui-react';
import Amplify from "aws-amplify";
import React from 'react';
import './App.css';
import awsconfig from "./aws-exports";
import Routes from './Routes';

Amplify.configure( awsconfig );
/**
 * @description App: Handles The Login / Register / Forgot My Page. All Variables Are Configured In Amazon Cognito User Pools
 * @date 22/11/2021
 *
 * @param  {"Email"}
 * @param  {"username"}}
 * @param  {"Password"}
 * @param  {"new-password"}
 * @param  {"given_name"}
 * @param  {"family_name"}
 * @param  {"address"}
 * @param  {"custom:city"}
 * @param  {"custom:province"}
 * @param  {"custom:country"}
 * @param  {"phone_number"}
 * @param  {"custom:cardholder"}
 * @param  {"custom:billingcardnumber"}
 * @param  {"custom:validmonth"}
 * @param  {"custom:validyear"}
 * @param  {"custom:billingcvv"}
 * @param  {"custom:altphonenumber"}
 *
 * @returns {*}
 */
function App()
{
  return (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp
        slot="sign-up"
        // A User's Email Address Will Also Be Their Username
        usernameAlias="email"
        formFields={[{
          // Email Variable Saved To AWS Cognito
          type: "email",
          // Email Label For Form
          label: "Email",
          placeholder: "",
          inputProps: {
            // Prevents User From Creating An Account Will A Null Value
            required: true,
            autocomplete: "username"
          },
        },
        {
          type: "password",
          label: "Password",
          placeholder: "Req: UpperCase, LowerCase, Number, Min. 8 Chars",
          inputProps: {
            required: true,
            autocomplete: "new-password"
          },
        },
        {
          type: "given_name",
          label: "First Name",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "family_name",
          label: "Last Name",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "address",
          label: "Address",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "custom:city",
          label: "City",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "custom:province",
          label: "Province",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "custom:country",
          label: "Country",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "phone_number",
          label: "Phone Number",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "custom:farm_name",
          label: "Farm Name",
          placeholder: "",
          inputProps: {
            required: true
          },
        },
        {
          type: "custom:lat",
          label: "Farm Latitude",
          placeholder: "00.0000",
          inputProps: {
            required: true
          },
        },
        {
          type: "custom:lon",
          label: "Farm Longitude",
          placeholder: "-00.0000",
          inputProps: {
            required: true
          },
        }
        ]} />
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
      
      <div className="App">
        <Routes />
      </div>
    </AmplifyAuthenticator>
  );
}

export default App;