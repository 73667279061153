export const MenuItems = [
    {
        title: 'Dashboard',
        route: '/',
        icon: <i class="bi bi-house"></i>,
        cName: 'menu-links'
    },
    {
        title: 'Profile',
        route: '/Profile',
        icon: <i class="bi bi-person-circle"></i>,
        cName: 'menu-links'
    },
    {
        title: 'Calendar',
        route: '/calendar',
        icon: <i class="bi bi-calendar-date"></i>,
        cName: 'menu-links'
    },
    {
        title: 'Subscribe',
        route: '/Subscribe',
        icon: <i class="bi bi-credit-card"></i>,
        cName: 'menu-links'
    },
    {
        title: 'EnnsBrothers.com',
        route: '/EnnsBrothers',
        icon: <i class="bi bi-box-arrow-in-right"></i>,
        cName: 'menu-links'
    },
    {
        title: 'MyJohnDeere.com',
        route: '/JohnDeere',
        icon: <i class="bi bi-box-arrow-in-right"></i>,
        cName: 'menu-links'
    },
    {
        title: 'Contact Us',
        route: '/ContactUs',
        icon: <i class="bi bi-chat-left-text"></i>,
        cName: 'menu-links'
    },
    {
        title: 'Sign Out',
        route: '/SignOut',
        icon: <i class="bi bi-door-open"></i>,
        cName: 'menu-links'
    },

]