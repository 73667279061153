import DeleteNote from "./DeleteNote";

function HistNotes({histid, text, dateAdded, handleFetchNote}) {
    return (
        <div className="note">
            <span>{text}</span>
            <div className="note-footer">
                <small className="dateFooter">Date Added: {dateAdded}</small>
                <button className="note-delete-btn" onClick={()=> DeleteNote(dateAdded, handleFetchNote)}>Delete</button>
            </div>
        </div>
    )
}

export default HistNotes;