import React from "react";
import { BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import Home from './Pages/Home.js'
import AddField from './Pages/AddField';
import Weather from './Components/GetWeather';
import MainNavbar from './Components/Navbar/MainNavbar';
import ContactUs from './Pages/ContactUs';
import ContactSuccess from './Pages/ContactSuccess';
import SignOut from './Components/SignOut';
import CalendarData from './Pages/Calendar';
import Profile from './Pages/Profile'
import ChangeEmail from "./Pages/ChangeEmail";
import ChangePassword from "./Pages/ChangePassword";
import ChangeInfo from "./Pages/ChangeInfo";
import EnnsRedirect from "./Components/LinkToEnns";
import Subscribe from "./Pages/Subscribe.js";
import HistoricDataForField from "./Pages/HistoricDataForField.js";
import JDeereRedirect from "./Components/LinkToJohnDeere.js";
import EnvCanRedirect from "./Components/LinkToEnvCan.js";
import GCCanadaAPIRedirect from "./Components/LinkToEnvCanadaAPI.js";
import WeatherNetRedirect from "./Components/LinkToWeatherNet.js";
import Admin from "./Pages/Admin.js";
import Fields from "./Pages/Fields.js";
import Contact from "./Pages/Contact";
import ThankYou from "./Pages/ThankYou.js";
import Confirmation from "./Pages/Confirmation.js";
import ViewUserInfo from "./Pages/ViewUserInfo.js";

export const Routes = () => {
    return(
        <BrowserRouter>
            <MainNavbar />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/AddField">
                        <AddField />
                    </Route>
                    <Route path="/calendar">
                        <CalendarData />
                    </Route>
                    {/* <Route path="/SignIn">
                        <Login />
                    </Route> */}
                    <Route path="/GetWeather">
                        <Weather />
                    </Route>
                    <Route path="/EnnsBrothers">
                        <EnnsRedirect />
                    </Route>
                    <Route path="/Profile">
                        <Profile />
                    </Route>
                    <Route path="/SignOut">
                    <Redirect from='/SignOut' to="/" />
                        <SignOut />
                    </Route>
                    <Route path="/ContactUs">
                        <ContactUs />
                    </Route>
                    <Route path="/ContactSuccess">
                        <ContactSuccess />
                    </Route>
                    <Route path="/ChangeEmail">
                        <ChangeEmail />
                    </Route>
                    <Route path="/ChangePassword">
                        <ChangePassword/>
                    </Route>
                    <Route path="/ChangeInfo">
                        <ChangeInfo />
                    </Route>
                    <Route path="/Subscribe">
                        <Subscribe />
                    </Route>
                    <Route path="/ThankYou">
                        <ThankYou />
                    </Route>
                    <Route path="/Confirmation">
                        <Confirmation />
                    </Route>
                    <Route path="/HistoricDataForField">
                        <HistoricDataForField />
                    </Route>
                    <Route path="/JohnDeere">
                        <JDeereRedirect />
                    </Route>
                    <Route path="/EnvCanada">
                        <EnvCanRedirect />
                    </Route>
                    <Route path="/EnvCanadaAPI">
                        <GCCanadaAPIRedirect />
                    </Route>
                    <Route path="/WeatherNet">
                        <WeatherNetRedirect />
                    </Route>
                    <Route path="/Admin">
                        <Admin />
                    </Route>
                    <Route path="/ViewUserInfo">
                        <ViewUserInfo />
                    </Route>
                    <Route path="/Field">
                        <Fields />
                    </Route>
                    <Route path="/Contact">
                        <Contact />
                    </Route>
                </Switch>
        </BrowserRouter>
    )
}

export default Routes