
import React from "react";

const ContactSuccess = () => (
  <div>
    <h1 className="title is-1">Successfully Sent A Message</h1>
    <p>
      Your message has been sent.
    </p>
  </div>
);

export default ContactSuccess;