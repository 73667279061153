import
  {
    AmplifySignOut
  } from '@aws-amplify/ui-react';
import
  {
    Auth
  } from 'aws-amplify';
import React from 'react';
/**
 * @description SignOut: Signs Out The Current Authenticated User From The AWS Cognito User Pool
 * @returns {*}  
 */
function SignOut() {
  return (
      Auth.signOut()
      .then( data => console.log( data ) )
      .catch( err => console.log( err ) ),
    <AmplifySignOut></AmplifySignOut>
  );
  }
  export default SignOut;