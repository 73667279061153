import { Auth, API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { listUserSubscriptions } from '../graphql/queries';
import AssignDevicesStatuses from './Subscriptions/AssignDevicesStatuses';

function CurrentSubscriptions() {
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        fetchSubscriptions();
    }, []);
 
    /**
     * Fetches all stations that the current user is subscribed to and stores them in 'devices' state variable.
     */
    const fetchSubscriptions = async () => {
        try{
            let user = await Auth.currentAuthenticatedUser();
            let userId = user['username'];

            const subscriptionData = await API.graphql(graphqlOperation(listUserSubscriptions, {USER_ID: userId}));
            const subscriptionDataList = subscriptionData.data.listUserSubscriptions.items;

            setSubscriptions(AssignDevicesStatuses(subscriptionDataList));
        } catch(error){
            //console.log(error);
        }
    }
    
    /**
     * Returns JSX code that displays information about the user's device subscription.
     * 
     * @param {Object} subscription - The subscription info about a device to display.
     * @param {string} subscription.DEVICE_IDENTIFICATION_NUMBER - The ID of the device that the user is subscribed to.
     * @param {string} subscription.END_DATE - The end date of the subscription.
     * @param {string} subscription.STATUS - The status of the subscription.
     * 
     * @returns JSX code that displays the subscribed device information and its current status.
     */
    function SubscribedDevice(subscription) {
        let statusIndicator;
        let subscriptionStatus;
        let subscriptionActiveSince;
    
        if(subscription.STATUS === "Active"){
            subscriptionStatus = `Expires: ${subscription.END_DATE}`;
            statusIndicator = "circleC";
            subscriptionActiveSince = `Active since: ${subscription.START_DATE}`;
        }
        else if(subscription.STATUS === "Pending"){
            statusIndicator = "circleB";
            subscriptionStatus = "Pending";
        }
        else if(subscription.STATUS === "Expiring"){
            let endDate = new Date(subscription.END_DATE);
            let today = new Date();
            
            // Finds the difference in days between today and subscription end date
            let difference = endDate.getTime() - today.getTime();
            let days = Math.ceil(difference / (1000 * 3600 * 24));
    
            subscriptionStatus = `Expires in ${days} days`;
            statusIndicator = "circleB";
        }
        else if(subscription.STATUS === "Expired"){
            statusIndicator = "circleD";
            subscriptionStatus = `Expired: ${subscription.END_DATE}`;
        }
    
        return(
            <div className="CurrentSubscriptionItem" key={subscription.DEVICE_IDENTIFICATION_NUMBER}>
                <span className={statusIndicator}></span>
                <div className="CurrentSubItem">
                    <p><b>{subscription.DEVICE_NAME}</b></p>
                    <p>Station ID: {subscription.DEVICE_IDENTIFICATION_NUMBER}</p>
                    <p>{subscriptionStatus}</p>
                    {subscriptionActiveSince &&
                        <p>{subscriptionActiveSince}</p>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="CurrentSubscriptionsContainer">
            <div id="CurrentSubscription">
                <h3>Current Subscriptions</h3>
                {subscriptions.map((subscription) => (
                    SubscribedDevice(subscription)
                ))}
            </div>
        </div>
    )

}

export default CurrentSubscriptions;