import {yupResolver} from '@hookform/resolvers/yup';
import Amplify, {Auth} from 'aws-amplify';
import {Button,Container,Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import * as yup from 'yup';
import awsExports from '../aws-exports';
import '../Styles/Register.css';


Amplify.configure( awsExports );
/**
 * @description schema: Provides Form Validation For The ContactUs Form.
 */

let schema = yup.object().shape( {
	// Validation: First Name Must Not Be Null.
	Subject_Line: yup.string().required( 'PLEASE ENTER YOUR SUBJECT LINE' ),
	Message: yup.string().required( "PLEASE ENTER YOUR MESSAGE" ),
} );

/**
 * @description addMessage: This Function Assigns The Variables Values Based On User Input.
 * @param  {formState.Last_Name} Last_Name
 * @param  {formState.Message} Message
 * @returns formState
 */
async function sendMessage() {
	
	const data = {
			Subject_Line: formState.Subject_Line,
			Message: formState.Message,
	}

	let user = await Auth.currentAuthenticatedUser();
	console.log(user);

	if (!user.attributes['custom:farm_name']){
		user.attributes['custom:farm_name'] = 'NO FARM NAME';
	}
	
	try{
		await fetch("https://8sxjhg1f4l.execute-api.us-east-2.amazonaws.com/sendEmail", {
				mode: "no-cors",
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify( {
					senderName: "weather@ennsbros.com",
					senderEmail: user.attributes['email'],
					subject: user.attributes['custom:farm_name'] + ' - ' + data.Subject_Line,
					message: data.Message + ' Sent By - ' + user.attributes['given_name'] + '' + user.attributes['family_name'] + ' - ' + user.attributes['email'] + ' ' + user.attributes['phone_number'],
					date: new Date(),
				})});
	} catch(error){
		console.log(error);
	}
}
// Sets The Initial Form State To Empty
const formState = {
	Subject_Line: '',
	Message: '',
};
/**
 * @description Updates The State Of The Form Based On User Input
 * @param {*} key 
 * @param {*} value
 */
function updateFormState( key, value ) {
	formState[ key ] = value;
	
}
/**
 * @description ContactUs: Validates The Form For Errors
 * If Errors Are Detected It Will Display Errors On Screen
 * Resets Form After Successful Submition
 */
function ContactUs() {
	let history = useHistory();
	const {
		register,
		handleSubmit,
		reset,
		formState: {
			errors
		},
	} = useForm( {
		resolver: yupResolver( schema ),
	} );
// On Submit Will Send Data Defined Above To The ContactUsAPI Using The /ContactSuccess LAMBDA Function
	const onSubmit = () => {
		sendMessage();
		history.push( "/ContactSuccess" );
		reset();
	};

	return (
        <Container id="mains">
        <div id="addField" >
				<br />
                <Form classname="name" onSubmit={handleSubmit(onSubmit)}>
					<Form.Group className='formGroup'>
						<Form.Label className='fieldName'>Subject</Form.Label>
						<Form.Control
							placeholder='Subject'
							{...register('Subject_Line', {
								required: 'Subject_Line is required',
							})}
							onInput={(e) => updateFormState('Subject_Line', e.target.value )}
						/>
						{errors.Subject_Line?.message && <p>{errors.Subject_Line?.message}</p>}
					</Form.Group>

					<Form.Group className='formGroup'>
						<Form.Label className='fieldName'>Message</Form.Label>
						<Form.Control
							as="textarea" rows={5}
							placeholder='Message' id='messageArea'
							{...register('Message', { required: 'Message' })}
							onInput={(e) => updateFormState('Message', e.target.value)}
						/>
						{errors.Message?.message && <p>{errors.Message?.message}</p>}
					</Form.Group>

					<Button
					type='submit'
						onClick={() => {
						}}>
						<span className='registerButton'>Send</span>
					</Button>
				</Form>
			</div>
		</Container>
	);
}

export default ContactUs;
