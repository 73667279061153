import { Auth } from 'aws-amplify';
import '../../Styles/Map.css';
import '../API/env.local';

/**
 * Sets farmCoordinates state to the coordinates of the user's farm using Google Geocoding API and localStorage.
 * 
 * @param {*} setFarmCoordinates - farmCoordinates setState.
 */
 const GetFarmCoordinates = async (setFarmCoordinates, setMapCenter) => {
    // Fetches user data that will be used in Geocoding API call
    const user = await Auth.currentAuthenticatedUser();
    const userLat = Number((user['attributes']["custom:lat"]));
    const userLon = Number((user['attributes']["custom:lon"]));

    setFarmCoordinates({lat: userLat, lng: userLon});
    if(typeof setMapCenter !== 'undefined'){
        setMapCenter({lat: userLat, lng: userLon});
    }
}
 
export default GetFarmCoordinates;