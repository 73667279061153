import DeleteNote from "./DeleteNote";

//Notes function Component
function Notes({id, text, dateAdded, handleFetchNote}) {
    return (
        <div>
            <div className="note">
                <span>{text}</span>
                <div className="note-footer">
                    <small className="dateFooter">Date Added: {dateAdded}</small>
                    <button className="note-delete-btn" onClick={()=> DeleteNote(dateAdded, handleFetchNote)}>
                    <i class="bi bi-trash"></i></button>
                </div>
            </div>
        </div>
    )
}

export default Notes;